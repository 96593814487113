import { AppBar, Badge, Button, ButtonGroup, IconButton, styled } from "@mui/material";

export const NavButton = styled(IconButton)(({ theme }) => ({
  transition: "opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)",
  opacity: ".8",
  color: "#f5f5f7",
  backgroundColor: "transparent",
  "&:hover": {
    opacity: "1",
    backgroundColor: "transparent",
    
  },
}));
export const LanguageButtons = styled(ButtonGroup)(({ theme }) => ({
  transition: "opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)",
  opacity: ".8",
  color: "#f5f5f7",
  backgroundColor: "transparent",
  
  "&:hover": {
    opacity: "1",
    backgroundColor: "transparent",
  },
}));
export const LanguageButton = styled(Button)(({ theme }) => ({
  transition: "opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)",
  opacity: ".8",
  color: "#f5f5f7",
  backgroundColor: "transparent",
  padding: "2px",
  minWidth:"30px !important",
  border: "0.5px solid#f5f5f7",
  "&:hover": {
    opacity: "1",
    backgroundColor: "transparent",
    border: "1px solid#f5f5f7",
  },
}));
export const CusAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "rgba(0,0,0,0.8)",
  "& .css-1t29gy6-MuiToolbar-root": {
    minHeight: "40px",
    "@media (min-width: 600px)": {
      minHeight: "50px",
    },
  },
}));

export const BadgeAppl = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 4,
    top: 24,
    background: "#f5f5f7",
    color: "#1d1d1f",
    border: `2px solid rgba(0,0,0,0.8)`,
    padding: "0 4px",
  },
}));
