import React, { useEffect, useState, createContext, useContext } from "react";
import firebase from "../utils/firebase";
import { useAuth } from "./Auth";
export const MessagesContext = createContext();

const MessagesContextProvider = ({ children }) => {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [messageCurrent, setMessageCurrent] = useState(null);
  useEffect(() => {
    if (user) {
      const uid = user.uid;
      const unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("appMessages")
        .where("delivered", "==", false)
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            const arr = [];
            snapshot.forEach((doc) => {
              arr.push({ ...doc.data(), messageId: doc.id });
            });
            setMessageCurrent(arr[0]);
            setMessages(arr);
          } else {
            setMessageCurrent(null);
            setMessages([]);
          }
        });
      return unsubscribe;
    }
    return;
  }, [user]);
  useEffect(() => {
    if (user) {
      const uid = user.uid;
      const unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("appMessages")
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            const arr = [];
            snapshot.forEach((doc) => {
              arr.push({ ...doc.data(), messageId: doc.id });
            });
            setAllMessages(arr);
          } else {
            setAllMessages([]);
          }
        });
      return unsubscribe;
    }
    return;
  }, [user]);
  const messageDelivered = () => {
    if (messageCurrent.messageId) {
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("appMessages")
        .doc(messageCurrent.messageId)
        .update({ delivered: true });
    } else {
      if (messages.length > 0) {
        setMessageCurrent(messages[0]);
      } else {
        setMessageCurrent(null);
      }
    }
  };
  return (
    <MessagesContext.Provider
      value={{ messages, messageDelivered, messageCurrent, setMessageCurrent, allMessages }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);

export default MessagesContextProvider;
