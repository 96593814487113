import {
  Button,
  ButtonGroup,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useLanguage } from "../../contexts/Language";
import SButton from "../SButton";
import {
  CardBody,
  CardTitle,
  CusCard,
  MyDialogTitle,
  StyledCardMedia,
} from "./Products.mui.style";
import { ButtonWrapper, DialogImage } from "./Products.style";

import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const CloseButton = ({ closeDialogs }) => {
  return (
    <div
      style={{
        position: "sticky",
        top: "2%",
        marginLeft: "auto",
        marginRight: "2%",
        zIndex: 10,
      }}
    >
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        onClick={() => closeDialogs()}
        aria-label="close"
      >
        <AiOutlineClose />
      </IconButton>
    </div>
  );
};

var formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const AltButtons = ({ adet, language, handleSubmit }) => {
  const navigate = useNavigate();
  const [eklendi, setEklendi] = useState(false);
  const buttonRef = useRef();
  return (
    <ButtonWrapper >
      <button ref={buttonRef} type="submit" style={{display:"none"}}></button>
      <Button
        
        disabled={adet === 0}
        
        variant="contained"
        size="small"
        onClick={(e) => {buttonRef.current.click();setEklendi(true)}}
      >
        {language == "tr" && "Sepete Ekle"}
        {language == "de" && "zum Warenkorb hinzufügen"}
        {language == "en" && "Add to cart"}
      </Button>

      <Button
        disabled={adet === 0}
        variant="contained"
        size="small"
    
        onClick={(e) => {if(!eklendi){buttonRef.current.click()};navigate("/sepet");}}
      >
        {language == "tr" && "Sepete Git"}
        {language == "de" && "in den Warenkorb"}
        {language == "en" && "Go To Cart"}
      </Button>
    </ButtonWrapper>
  );
};


const Product = ({
  image,
  index,
  dialogs,
  setDialogs,
  closeDialogs,
  title,
  text,
  optional,
  productId,
  options,
  adet,
  setAdet,
  seciliUrun,
  setSeciliUrun,
  products,
  handleSubmit,
  size,
}) => {
  let tmp_options = {
    desc: "600gr",
    id: 21,
  };
  const urunsayisi = 12;
  let arr = Array(urunsayisi).fill(false);
  arr[index] = true;
  const { languageIndex, language } = useLanguage();
  return (
    <>
      <Grid item xs={12} sm={6} md={size}>
        <CusCard >
          <StyledCardMedia component="img" image={image} />
          <Stack direction="column" alignItems="center" sx={{ py: 2, px: 1 }}>
            <CardTitle variant="h5">{title[languageIndex]}</CardTitle>
            <CardBody variant="body2" alignItems={"center"}>
              {text[languageIndex]}
            </CardBody>

            <SButton
              onClick={() => {
                setDialogs(arr);
                if (!optional) setSeciliUrun(productId);
              }}
            >
              <Stack direction="row">
                <Typography variant="subtitle2" fontSize="11px">
                  {language == "tr" && "siparis ver"}
                  {language == "de" && "bestellung"}
                  {language == "en" && "order"}
                </Typography>
              </Stack>
            </SButton>
          </Stack>
        </CusCard>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={dialogs[index]}
          onClose={closeDialogs}
        >
          <CloseButton closeDialogs={closeDialogs} />
          <DialogImage src={image} alt="" />
          <MyDialogTitle>{title[languageIndex]}</MyDialogTitle>
          <Typography sx={{ color: "#6e6e73", px: 2, py: 1 }}>
            {text[languageIndex]}
          </Typography>
          <form onSubmit={handleSubmit}>
            {optional && (
              <FormControl variant="standard" sx={{ m: 2 }}>
                <FormLabel component="legend">
                  {language == "tr" && "Seçiminizi yapınız."}
                  {language == "de" && "Treffen Sie Ihre Wahl."}
                  {language == "en" && "Make your selection."}
                </FormLabel>
                <RadioGroup row onChange={(e) => setSeciliUrun(e.target.value)}>
                  {options.map((item) => (
                    <FormControlLabel
                      key={Math.random()}
                      control={<Radio />}
                      value={item.id}
                      label={item.desc}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}

            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{
                px: 2,
                py: 1,
                bgcolor: "background.default",
                position: "sticky",
                bottom: 0,
                zIndex: 1,
              }}
            >
              <ButtonGroup
                size={window.innerWidth > 600 ? "medium" : "small"}
                aria-label="small outlined button group"
              >
                <Button
                  onClick={() => setAdet(adet + 1)}
                  sx={window.innerWidth < 600 ?{ fontWeight: "400 !important",padding:"0 !important",minWidth:"20px !important" }:{ fontWeight: 700 }}
                  
                >
                  +
                </Button>
                {adet > 0 && <Button sx={window.innerWidth < 600 ?{ fontWeight: "500 !important",padding:"0 !important",minWidth:"30px !important" }:{ color: "#333" }}>{adet}</Button>}
                {adet > 0 && (
                  <Button
                    onClick={() => setAdet(adet - 1)}
     
                    sx={window.innerWidth < 600 ?{ fontWeight: "400 !important",padding:"0 !important",minWidth:"20px !important" }:{ fontWeight: 700 }}
                  >
                    -
                  </Button>
                )}
              </ButtonGroup>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                <Typography  sx={window.innerWidth < 600 ?{ fontSize: "15px",fontWeight: 700 }:{ fontWeight: 700 }} color={"primary"}>
                  {seciliUrun && formatter.format(products[seciliUrun]["price"] * adet)}
                </Typography>
                <Typography
                  sx={window.innerWidth < 600 ?{ fontSize: "9px" }:{ fontWeight: 700,fontSize: "11px" }}
                  
                  color={!seciliUrun ? "error" : "primary"}
                >
                  {!seciliUrun
                    ? <>
                      {language == "tr" && "secim yapınız"}
                      {language == "de" && "eine Wahl treffen"}
                      {language == "en" && "make a choice"}
                    </>
                    : <>
                      {language == "tr" && "KDV dahil artı nakliye"}
                      {language == "de" && "inkl. MwSt. zzgl. Versand"}
                      {language == "en" && "incl. VAT plus shipping"}
                    </>}
                </Typography>

              </div>


              <AltButtons adet={adet} handleSubmit={handleSubmit} language={language} />
            </Stack>
          </form>
        </Dialog>
      </Grid>
    </>
  );
};

export default Product;
