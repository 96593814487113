import { Container } from "@mui/material";
import React from "react";
import { useLanguage } from "../../contexts/Language";
import { FooterWrapper, FooterContainer, FooterLink } from "./Footer.style";

const Footer = () => {
  const { language } = useLanguage();
  return (
    <FooterContainer>
      <Container>
        <FooterWrapper className="container">
          <div className="title">
            {language == "tr" && "Acinin en lezzetli hali."}
            {language == "de" && "Die leckerste Form der Schärfe."}
            {language == "en" && "The most delicious kind of bitter."}
          </div>
          <div className="legal">
            <p className="copy">
              Copyright © 2022 Cigkofteshop. All rights reserved.
            </p>
            <div>
              <FooterLink to={"/queryshippingnumber"}>
                Get Shipping Number by order number
              </FooterLink>
              <FooterLink to={"/termsofuse"}>Terms of use</FooterLink>
              <FooterLink to={"/contact"}>Contact us</FooterLink>
            </div>
          </div>
        </FooterWrapper>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
