import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PagesWrapper from "../../../components/PagesWrapper";
import { useAdminMessages } from "../../../contexts/AdminMessages";
import { useAuth } from "../../../contexts/Auth";

import firebase from "../../../utils/firebase";

const Message = ({
  uName,
  lName,
  phone,
  email,
  message,
  time,
  timestamp,
  uid,
}) => {
  const { saatFark } = useAdminMessages();
  return (
    <Card
      sx={
        new Date().getTime() - 1000 * 60 * 60 * saatFark < timestamp
          ? { minWidth: 275, height: "100%", border: "1px solid blue" }
          : { minWidth: 275, height: "100%" }
      }
    >
      <CardContent>
        {new Date().getTime() - 1000 * 60 * 60 * saatFark < timestamp ? (
          <Typography sx={{ color: "blue" }} gutterBottom>
            New
          </Typography>
        ) : (
          ""
        )}

        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {uName + " " + lName + " | " + time}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {uid}
        </Typography>

        <hr />
        <Typography gutterBottom variant="body2">
          {message}
        </Typography>
        <hr />
        <Typography gutterBottom>{email}</Typography>
        <Typography gutterBottom>{phone}</Typography>
      </CardContent>
    </Card>
  );
};

const AdminMessages = () => {
  const { user } = useAuth();
  const { messages, setMessages, saatFark, setSaatFark } = useAdminMessages();

  return (
    <PagesWrapper>
      <Typography style={{ margin: "20px", textAlign: "center" }}>
        
        Contact sayfasindan alinan mesajlar. Eger gerekli ise telefondan veya email ile cevap verin
        <br/>
        Son bir saatteki mesajlar yeni olarak degerlendirilir.
      </Typography>
      <Typography style={{ margin: "20px", textAlign: "center" }} color="text.secondary">
        Dev Todos:
        <br/>
        Mesajlari silme ozelligi eklenecek.
      </Typography>
      <Grid container alignItems="stretch" spacing={3}>
        {messages.map((item) => (
          <Grid item xs={12} md={4}>
            <Message {...item} />
          </Grid>
        ))}
      </Grid>
    </PagesWrapper>
  );
};

export default AdminMessages;
