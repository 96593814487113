import React, { useEffect, useState, createContext, useContext } from "react";
import firebase from "../utils/firebase";
import { useAuth } from "./Auth";
export const AdminMessagesContext = createContext();

const AdminMessagesContextProvider = ({ children }) => {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [saatFark, setSaatFark] = useState(1);
  const [newMessages, setNewMessages] = useState([])
  useEffect(() => {
    if (user && user.email == "cigkofteshopcom@gmail.com") {
      const uid = user.uid;
      const unsubscribe = firebase
        .firestore()
        .collection("cmsg")
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            const arr = [];
            snapshot.forEach((doc) => {
              arr.push({ ...doc.data() });
            });
            setMessages(arr);
          } else {
            setMessages([]);
          }
        });
      return unsubscribe;
    }
    return;
  }, [user]);
  useEffect(() => {
    setNewMessages(messages.filter((item) => new Date().getTime() - 1000*60*60*saatFark < item.timestamp )); // 1 saat
  }, [messages,saatFark]);
  return (
    <AdminMessagesContext.Provider value={{ messages, setMessages, newMessages, saatFark, setSaatFark }}>
      {children}
    </AdminMessagesContext.Provider>
  );
};

export const useAdminMessages = () => useContext(AdminMessagesContext);

export default AdminMessagesContextProvider;
