import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import { useMessages } from "../../contexts/Messages";
import firebase from "../../utils/firebase";

function convertTZ() {
  var options = {
    hour: "2-digit",
    minute: "2-digit",
    month: "long",
    day: "numeric",
    timeZone: "Europe/Berlin",
  };
  return new Date().toLocaleString("de-DE", options);
}

const Contact = () => {
  const { user } = useAuth();
  const { setMessageCurrent } = useMessages();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [uName, setUName] = useState("");
  const handleNameChange = (event) => {
    setUName(event.target.value);
  };
  const [lName, setLName] = useState("");
  const handleLNameChange = (event) => {
    setLName(event.target.value);
  };
  const [email, setEmail] = useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const [phone, setPhone] = useState("");
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const [message, setMessage] = useState("");
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      uName.length > 1 &&
      lName.length > 0 &&
      phone.length > 1 &&
      email.length > 1 &&
      message.length > 1
    ) {
      firebase.firestore().collection("cmsg").add({
        uName,
        lName,
        phone,
        email,
        message,
        time: convertTZ(),
        timestamp: new Date().getTime(),
        uid: user.uid
      });
      setMessageCurrent({
        title: [
          "Mesajınız alınmıştır ve en kısa sürede sizinle iletişime geçilecektir.",
          "Wir haben Ihre Nachricht erhalten und werden uns so bald wie möglich mit Ihnen in Verbindung setzen.",
          "Your message has been received and you will be contacted as soon as possible.",
        ],
        message: ["", "", ""],
        delivered: false,
      });
      navigate("/");
    } else {
      enqueueSnackbar("please fill all required fields!", {
        variant: "warning",
      });
    }
  };

  return (
    <>
      <Grid style={{ margin: "50px" }}>
        <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" }}>
          <CardContent>
            <Typography gutterBottom variant="h5">
              Contact Us
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              Fill up the form and we will get back to you.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstname"
                    name="firstname"
                    label="First Name"
                    autoComplete="given-name"
                    value={uName}
                    onChange={handleNameChange}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastname"
                    name="lastname"
                    label="Last Name"
                    autoComplete="family-name"
                    value={lName}
                    onChange={handleLNameChange}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    multiline
                    rows={4}
                    value={message}
                    onChange={handleMessageChange}
                    placeholder="Type your message here"
                    variant="standard"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default Contact;
