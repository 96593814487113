import { Box, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import HeaderAdmin from "../../components/HeaderAdmin";

const AdminLayout = () => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <HeaderAdmin />

        <CssBaseline />
        <Outlet />
      </Box>
    </>
  );
};

export default AdminLayout;
