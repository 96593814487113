import { Box, Button, Card, CardActions, CardContent, Container, Dialog, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PagesWrapper from '../../../components/PagesWrapper';
import firebase from '../../../utils/firebase'

const Coupon = ({ couponCode, productDiscount, shippingDiscount, couponQuantity, remainingQuantity }) => {

    const handleDelete = () => {
        try {
            firebase
                .firestore()
                .collection("coupons")
                .doc(couponCode)
                .delete();
        } catch (e) {
            alert(e)
        }
    }
    return (
        <Card sx={{ minWidth: 275, height: "100%" }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {productDiscount + "% product discount"}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {shippingDiscount + "% shipping discount"}
                </Typography>
                <Typography variant="h5" component="div">
                    {couponCode}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {couponQuantity - remainingQuantity + " / " + couponQuantity}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {remainingQuantity + " pieces left"}
                </Typography>


            </CardContent>
            <CardActions>
                <Button size="small" onClick={handleDelete}>
                    Delete
                </Button>
            </CardActions>
        </Card>
    );
};

const AdminCoupons = () => {
    const [coupons, setCoupons] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [couponCode, setCouponCode] = useState()
    const [productDiscount, setProductDiscount] = useState("")
    const [shippingDiscount, setShippingDiscount] = useState("")
    const [couponQuantity, setCouponQuantity] = useState("")
    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection("coupons")
            .onSnapshot((snapshot) => {
                if (!snapshot.empty) {
                    const arr = [];
                    snapshot.forEach((doc) => {
                        arr.push({ ...doc.data() });
                    });
                    setCoupons(arr);
                } else {
                    setCoupons([]);
                }
            });
        return unsubscribe;
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(isNaN(productDiscount) || isNaN(shippingDiscount) || isNaN(couponQuantity) ){
            alert("bir rakam girilmeli!");
            return;
        }
        if(Number(productDiscount) < 0 || Number(productDiscount) > 100){
            alert("discount 0 ile 100 arasi olmali!");
            return
        }
        if(Number(shippingDiscount) < 0 ||Number(shippingDiscount) > 100){
            alert("discount 0 ile 100 arasi olmali!");
            return
        }
        if(Number(couponQuantity) < 0){
            alert("discount 0 dan buyuk olmali!");
            return
        }
        if(couponCode.length < 0){
            alert("coupon codu girilmeli!");
            return
        }
        try {
            await firebase
                .firestore()
                .collection("coupons")
                .doc(couponCode)
                .set({ couponCode, productDiscount:Number(productDiscount), shippingDiscount:Number(shippingDiscount), couponQuantity:Number(couponQuantity), remainingQuantity: Number(couponQuantity) });
        } catch (e) {
            alert(e)
        }
        setOpenDialog(false)

    }
    return (
        <>
            <PagesWrapper>
                <Button onClick={() => setOpenDialog(true)}>Add new coupon</Button>
                <Grid container alignItems="stretch" spacing={3}>
                    {coupons.map((item) => (
                        <Grid item xs={12} md={4}>
                            <Coupon {...item} />
                        </Grid>
                    ))}
                </Grid>
            </PagesWrapper>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <Container sx={{ p: 3 }} component="form" onSubmit={handleSubmit}>
                    <Typography>Only english alphabet!</Typography>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                id="couponCode"
                                name="couponCode"
                                label="Coupon Code"
                                value={couponCode}
                                onChange={(e) => setCouponCode(("" + e.target.value).toUpperCase())}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="productDiscount"
                                name="productDiscount"
                                label="Product Discount(%)"
                                value={productDiscount}
                                onChange={(e) => setProductDiscount(e.target.value)}
                                
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="shippingDiscount"
                                name="shippingDiscount"
                                label="Shipping Discount(%)"
                                
                                value={shippingDiscount}
                                onChange={(e) => setShippingDiscount(e.target.value)}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="couponQuantity"
                                name="couponQuantity"
                                label="Coupon Quantity"
                               
                                value={couponQuantity}
                                onChange={(e) => setCouponQuantity(e.target.value)}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>

                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            type='submit'
                            sx={{ mt: 3, ml: 1 }}
                        >
                            APPLY
                        </Button>
                    </Box>
                </Container>
            </Dialog>



        </>
    )
}

export default AdminCoupons