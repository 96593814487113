import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
// const firebaseConfig = {
//   apiKey: "AIzaSyDfsJhAyeiBnJ78mPMpAkqlFLEj2vlncKo",
//   authDomain: "cigkofteshop.com",
//   databaseURL: "",
//   messagingSenderId: "867171246797",
//   projectId: "cigkofteshop",
//   storageBucket: "cigkofteshop.appspot.com",
// };
const firebaseConfig = {
  apiKey: "AIzaSyDeuTrq6pMBY4hEtnPJ-hXt7-HBnzMbXyI",
  authDomain: "cigkofteshopp.firebaseapp.com",
  projectId: "cigkofteshopp",
  storageBucket: "cigkofteshopp.appspot.com",
  messagingSenderId: "351412470302",
  appId: "1:351412470302:web:fe59a52419b77d5891640f"
};
firebase.initializeApp(firebaseConfig);

export default firebase;
