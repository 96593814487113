import {
  Grid,
  Typography,
  styled,
  CardMedia,
  Stack,
  Dialog,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Button,
  ButtonGroup,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SButton from "../../components/SButton";
import {
  CardBody,
  CardTitle,
  CusCard,
  MyDialogTitle,
  StyledCardMedia,
  SubTitle,
  Title,
} from "./Home.mui.style";
import {
  ButtonWrapper,
  DialogImage,
  Video,
  VideoWrapper,
  Wrapper,
} from "./Home.style";

import vakuum1 from "./media/vakuum1.png";
import vakuum2 from "./media/vakuum2.png";

import nar from "./media/nar.png";
import salgam from "./media/salgam.png";

import tursu from "./media/tursu.png";
import selinJalapenoBiberTursu from "./media/Selin-Jalapeno-Biber-Tursu.png";
import melisKarisikTursu from "./media/Melis-Karisik-Tursu.png";

import eceAlaturkaSarimsakliSos from "./media/Ece-Alaturka-Sarimsakli-Sos.png";
import eceThaiSarimsakliSos from "./media/Ece-Thai-Sarimsakli-Sos.png";
import eceSambalOelekAciBiberSos from "./media/Ece-Sambal-Oelek-Aci-Biber-Sos.png";
import eceSrirachaSuperChiliSos from "./media/Ece-Sriracha-Super-Chili-Sos.png";
import eceTatliFijneChiliSosu from "./media/Ece-Tatli-Fijne-Chili-Sosu.png";

import { AiOutlineClose } from "react-icons/ai";
import { products } from "./products";
import { useSnackbar } from "notistack";
import { useSepet } from "../../contexts/Sepet";
import { useLanguage } from "../../contexts/Language";
import Product from "../../components/product/Product";

const CloseButton = ({ closeDialogs }) => {
  return (
    <div
      style={{
        position: "sticky",
        top: "2%",
        marginLeft: "auto",
        marginRight: "2%",
        zIndex: 10,
      }}
    >
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        onClick={() => closeDialogs()}
        aria-label="close"
      >
        <AiOutlineClose />
      </IconButton>
    </div>
  );
};

var formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const AltButtons = ({ adet, language }) => {
  return (
    <ButtonWrapper>
      <Button
        disabled={adet === 0}
        type="submit"
        variant="contained"
        size="small"
      >
        {language == "tr" && "Sepete Ekle"}
        {language == "de" && "Hinzufügen"}
        {language == "en" && "Add to cart"}
      </Button>

      <Button
        disabled={adet === 0}
        type="submit"
        variant="contained"
        size="small"
      >
        {language == "tr" && "Sepete Git"}
        {language == "de" && "Mein Warenkorb"}
        {language == "en" && "Go To Cart"}
      </Button>
    </ButtonWrapper>
  );
};

const Home = () => {
  

  const { sepet, setSepet } = useSepet();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogs, setDialogs] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const { language } = useLanguage();
  const [seciliUrun, setSeciliUrun] = useState();
  const closeDialogs = () => {
    console.log("sss");
    setDialogs([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    setSeciliUrun(undefined);
    setAdet(1);
  };

  const [adet, setAdet] = useState(1);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (seciliUrun && adet != 0) {
      sepeteEkle(products[seciliUrun], adet);
      setAdet(1);
    } else {
      console.log("bir sorun var");
    }
  };
  const sepeteEkle = (urun, adet) => {
    enqueueSnackbar(adet + " - " + urun["title"][2], {
      variant: "info",
    });
    // eger sepette o urunden varsa sadece adetini arttiricaksin
    if (sepet.find((item) => item.id == urun.id) == null) {
      setSepet([...sepet, { ...urun, adet: adet }]);
    } else {
      setSepet((currentSepet) =>
        currentSepet.map((item) => {
          if (item.id == urun.id) {
            return { ...item, adet: item.adet + adet };
          } else {
            return item;
          }
        })
      );
    }
  };

  const constants = {
    dialogs,
    setDialogs,
    closeDialogs,
    adet,
    setAdet,
    seciliUrun,
    setSeciliUrun,
    products,
    handleSubmit,
  };

  return (
    <div>
      <Wrapper>
        <Grid container spacing={2} sx={{ px: 6, py: 5 }}>
          <Grid item sm={12} md={7}>
            <Title>Çigköfte.</Title>
          </Grid>
          <Grid item sm={12} md={5}>
            <div>
              <SubTitle>
                {language == "tr" && "Acının en lezzetli hali."}
                {language == "de" && "Die leckerste Form der Schärfe."}
                {language == "en" && "The most delicious kind of bitter."}
              </SubTitle>

              <Typography variant="body2" sx={{ color: "#6e6e73" }}>
                {language == "tr" &&
                  "Sevdikleriniz için eşsiz bir hediye fikri. Siz bir hediye verin, biz heryere gönderelim."}
                {language == "de" &&
                  "Eine einzigartige Geschenkidee für Ihre Lieben. Sie verschenken, wir versenden bundesweit"}
                {language == "en" &&
                  "A unique gift idea for your loved ones. You give a gift and we will send it anywhere."}
              </Typography>
            </div>
          </Grid>
        </Grid>
        {/* style="position:absolute;top:0;left:0;width:100%;height:100%;" ?title=0&byline=0&portrait=0&sidedock=0*/}
        <VideoWrapper>
          <div
            style={{
              width: "105%",
              position: "relative",
              bottom: "2.2vw",
              left: "5vw",
            }}
          >
            <iframe
            
              src="https://player.vimeo.com/video/763800057?controls=0&amp;autoplay=1&amp;loop=1&amp;autopause=0&amp;muted=1&amp;portrait=0&amp;wmode=opaque&amp;title=0&amp;byline=0&amp;sidedock=0"
              frameBorder="0"
              allow="autoplay; fullscreen;"
              allowFullScreen
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                width: "100%",
                height: "56.25vw",
              }}
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
          {/**<Video muted autoPlay loop playsInline src={vid}></Video> */}
        </VideoWrapper>
        <Grid
          container
          alignItems="center"
          columnSpacing={{ xs: 0, sm: 3, md: 6 }}
          rowSpacing={{ xs: 6, sm: 6, md: 6 }}
          sx={{ py: 10, px: 3 }}
        >
          {/*               Cigkofte              */}

          

          <Product
            {...constants}
            image={vakuum1}
            index={0}
            title={[
              "VAKUMLU NORMAL ACILI ÇİGKÖFTE",
              "VAKUUM MITTELSCHARFE ÇİGKÖFTE",
              "VAKUUM ÇİGKÖFTE",
            ]}
            text={[
              "ÇİGKÖFTE: Vegan, lezzetli ve sağlıklı spesiyalite: Çiğköfte. Vakumlu, salatasız ve garnitürsüz.",
              "ÇİGKÖFTE: Vegane, leckere und gesunde Spezialität aus der Türkei: die Cigköfte. Vakuumiert, ohne Salat und Beilagen.",
              "ÇİGKÖFTE: Vegan, delicious and healthy specialty from Turkey: the Cigköfte. Vacuum packed, without salad and garnishes.",
            ]}
            optional={true}
            options={[
              { id: 0, desc: "500g" },
              { id: 1, desc: "1kg" },
              { id: 2, desc: "2kg" },
              { id: 3, desc: "5kg" },
              { id: 4, desc: "10kg" },
            ]}
            size={6}
          />

          <Product
            {...constants}
            image={vakuum2}
            index={1}
            title={[
              "VAKUMLU ACILI ÇİGKÖFTE",
              "VAKUUM HOT ÇİGKÖFTE",
              "VAKUUM HOT ÇİGKÖFTE",
            ]}
            text={[
              "ACILI ÇİGKÖFTE: Vegan, lezzetli ve sağlıklı spesiyalite: Çiğköfte. Vakumlu, salatasız ve garnitürsüz.",
              "HOT ÇİGKÖFTE: Vegane, leckere und gesunde Spezialität aus der Türkei: die Cigköfte. Vakuumiert, ohne Salat und Beilagen.",
              "HOT ÇİGKÖFTE: Vegan, delicious and healthy specialty from Turkey: the Cigköfte. Vacuum packed, without salad and garnishes.",
            ]}
            optional={true}
            options={[
              { id: 5, desc: "500g" },
              { id: 6, desc: "1kg" },
              { id: 7, desc: "2kg" },
              { id: 8, desc: "5kg" },
              { id: 9, desc: "10kg" },
            ]}
            size={6}
          />
          <Product
            {...constants}
            image={vakuum1}
            index={0}
            title={[
              "VAKUMLU AZ ACILI ÇİGKÖFTE",
              "VAKUUM WENIGER SCHARFE ÇİGKÖFTE",
              "VAKUUM LOWSPICY ÇİGKÖFTE",
            ]}
            text={[
              "ÇİGKÖFTE: Vegan, lezzetli ve sağlıklı spesiyalite: Çiğköfte. Vakumlu, salatasız ve garnitürsüz.",
              "ÇİGKÖFTE: Vegane, leckere und gesunde Spezialität aus der Türkei: die Cigköfte. Vakuumiert, ohne Salat und Beilagen.",
              "ÇİGKÖFTE: Vegan, delicious and healthy specialty from Turkey: the Cigköfte. Vacuum packed, without salad and garnishes.",
            ]}
            optional={true}
            options={[
              { id: 29, desc: "500g" },
              { id: 30, desc: "1kg" },
              { id: 31, desc: "2kg" },
              { id: 32, desc: "5kg" },
              { id: 33, desc: "10kg" },
            ]}
            size={4}
          />

          {/*               Nareksisi - Salgam              */}

          <Product
            {...constants}
            image={salgam}
            index={2}
            title={[
              "Şalgam Suyu",
              "ŞALGAM (Rübensaft pikant)",
              "Rutabaga Juice",
            ]}
            text={[
              "Sağlıklı, probiyotik, biraz baharatlı - Türk şalgam suyu çiğköfte ile harika gidiyor!",
              "Gesund, pro-biotisch, etwas scharf - türkische Steckrübensaft passt hervorragend zu Cigköfte!",
              "Healthy, pro-biotic, a little spicy - Turkish rutabaga juice goes great with cigköfte!",
            ]}
            optional={true}
            options={[
              { id: 10, desc: "330ml" },
              { id: 12, desc: "1lt" },
            ]}
            size={4}
          />

          <Product
            {...constants}
            image={nar}
            index={3}
            title={[
              "NAR EKŞİSİ 1000gr",
              "Granatapfelsose 1000gr",
              "Pomegranate Syrup 1000gr",
            ]}
            text={[
              "Nar ekşisi, çiğköftenin geleneksel eşlikçisidir.",
              "Granatapfelsirup ist der traditionelle Begleiter von Cigköfte.",
              "Pomegranate syrup is the traditional accompaniment to cigköfte.",
            ]}
            optional={false}
            productId={14}
            size={4}
          />

          {/*               Tursular              */}
          <Product
            {...constants}
            image={tursu}
            index={4}
            title={[
              "Cubuk Salatalik Tursu",
              "Selin Eingelegte Wildgurken",
              "Selin Pickled Wild Cucumbers",
            ]}
            text={["", "", ""]}
            optional={true}
            options={[
              { id: 15, desc: "620g" },
              { id: 16, desc: "1600gr" },
              { id: 17, desc: "2500gr" },
              { id: 18, desc: "5kg" },
            ]}
            size={4}
          />
          <Product
            {...constants}
            image={selinJalapenoBiberTursu}
            index={5}
            title={[
              "Selin Jalapeno Biber Tursu",
              "Selin eingelegte Jalapeno",
              "Selin pickled jalapeno",
            ]}
            text={["", "", ""]}
            optional={true}
            options={[
              { id: 19, desc: "580gr" },
              { id: 20, desc: "1500gr" },
            ]}
            size={4}
          />

          <Product
            {...constants}
            image={melisKarisikTursu}
            index={6}
            title={[
              "Melis Karisik Tursu",
              "Melis Mischgemüse in Salzlake",
              "Melis Mixed Vegetables in Brine",
            ]}
            text={["", "", ""]}
            optional={true}
            options={[
              { id: 21, desc: "670gr" },
              { id: 22, desc: "2500gr" },
              { id: 23, desc: "5000gr" },
            ]}
            size={4}
          />

          {/*               Soslar              */}

          <Product
            {...constants}
            image={eceSrirachaSuperChiliSos}
            index={7}
            title={[
              "Ece Sriracha Super Chili Aci Biber Sos 660gr",
              "Ece Sriracha Super Chili Sose 660gr",
              "Ece Sriracha Super Chili Sauce 660gr",
            ]}
            text={[" ", " ", " "]}
            optional={false}
            productId={24}
            size={6}
          />

          <Product
            {...constants}
            image={eceSambalOelekAciBiberSos}
            index={8}
            title={[
              "Ece Sambal Oelek Aci Biber Sos 660gr",
              "Ece Sambal Oelek Sose 660gr",
              "Ece Sambal Oelek Sauce 660gr",
            ]}
            text={[" ", " ", " "]}
            optional={false}
            productId={25}
            size={6}
          />
          <Product
            {...constants}
            image={eceTatliFijneChiliSosu}
            index={9}
            title={[
              "Ece Fijne Chili Tatli Biber Sos 660gr",
              "Ece Fijne Chili Sauce 660gr",
              "Ece Fijne Chili Sauce 660gr",
            ]}
            text={[" ", " ", " "]}
            optional={false}
            productId={26}
            size={4}
          />
          <Product
            {...constants}
            image={eceAlaturkaSarimsakliSos}
            index={10}
            title={[
              "Ece Alaturka Sarimsakli Sos 660gr",
              "Ece Alaturka Garlic Sose 660gr",
              "Ece Alaturka Garlic Sauce 660gr",
            ]}
            text={[" ", " ", " "]}
            optional={false}
            productId={27}
            size={4}
          />

          <Product
            {...constants}
            image={eceThaiSarimsakliSos}
            index={11}
            title={[
              "Ece Thai Sarimsakli Sos 660gr",
              "Ece Thai Garlic Sose 660gr",
              "Ece Thai Garlic Sauce 660gr",
            ]}
            text={[" ", " ", " "]}
            optional={false}
            productId={28}
            size={4}
          />
        </Grid>
      </Wrapper>
    </div>
  );
};

export default Home;

// {seciliUrun && (
//   <Typography
//     fontWeight={400}
//     fontSize={"8px"}
//     color={"primary"}
//   >
//     {language == "tr" && "KDV dahil"}
//     {language == "de" && "inkl. MwSt."}
//     {language == "en" && "incl. VAT"}
//   </Typography>
// )}
