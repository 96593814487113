import styled from "styled-components";
export const DialogImage = styled.img`
  width: 80%;
  max-width: 500px;
  margin: auto;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @media only screen and (max-width: 500px) {
    button {
      font-size: 10px;
      line-height: 1.2;
    }
    flex-direction: column;
  }
`;
