import { CssBaseline } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
import MessageDialog from "../components/MessageDialog";
import { useAuth } from "../contexts/Auth";
import Address from "../pages/address/Address";
import AdminCoupons from "../pages/admin/adminCoupons/AdminCoupons";
import AdminHome from "../pages/admin/adminHome/AdminHome";
import AdminLayout from "../pages/admin/AdminLayout";
import AdminMessages from "../pages/admin/adminMessages/AdminMessages";
import AdminNewOrders from "../pages/admin/adminNewOrders/AdminNewOrders";
import AdminOrder from "../pages/admin/adminOrder/AdminOrder";
import AdminShippedOrders from "../pages/admin/adminShippedOrders/AdminShippedOrders";
import AdminSignIn from "../pages/admin/adminSignIn/AdminSignIn";
import Contact from "../pages/contact/Contact";
import Checkout from "../pages/dene/checkout/Checkout";

import Home from "../pages/home/Home";
import MainLayout from "../pages/MainLayout";
import Messages from "../pages/messages/Messages";
import Payment from "../pages/payment/Payment";
import QueryShippingNumber from "../pages/queryshippingnumber/QueryShippingNumber";
import Review from "../pages/review/Review";
import Sepet from "../pages/sepet/Sepet";
import TermsOfUse from "../pages/termsofuse/TermsOfUse";

const AppRouter = () => {
  const { user } = useAuth();
  if (user && user.email == "cigkofteshopcom@gmail.com") {
    console.log("allowed");
  }
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (user) {
      setIsAdmin(user.email == "cigkofteshopcom@gmail.com");
    } 
  }, [user]);

  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/sepet" element={<Sepet />} />
          <Route path="/address" element={<Address />} />
          <Route path="/review" element={<Review />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/queryshippingnumber" element={<QueryShippingNumber />} />
        </Route>
        <Route path="/dene" element={<Checkout />} />
        <Route element={isAdmin ? <AdminLayout /> : (user ? <AdminSignIn /> : <></>)}>
          <Route
            path="/admin"
            element={isAdmin ? <AdminHome /> : (user ? <AdminSignIn /> : <></>)}
          />

          <Route
            path="/admin/order/:id"
            element={isAdmin ? <AdminOrder /> : (user ? <AdminSignIn /> : <></>)}
          />
          <Route
            path="/admin/shippedorders"
            element={isAdmin ? <AdminShippedOrders /> : (user ? <AdminSignIn /> : <></>)}
          />
          <Route
            path="/admin/coupons"
            element={isAdmin ? <AdminCoupons/> : (user ? <AdminSignIn /> : <></>)}
          />
          <Route
            path="/admin/neworders"
            element={isAdmin ? <AdminNewOrders /> : (user ? <AdminSignIn /> : <></>)}
          />
          <Route
            path="/admin/messages"
            element={isAdmin ? <AdminMessages /> : (user ? <AdminSignIn /> : <></>)}
          />
        </Route>
        <Route path="/admin/signin" element={<AdminSignIn />} />
      </Routes>

      <MessageDialog />
    </BrowserRouter>
  );
};

export default AppRouter;
