import {
  Container,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import DirectionsIcon from "@mui/icons-material/Directions";
import firebase from "../../utils/firebase";
import { useMessages } from "../../contexts/Messages";

const QueryShippingNumber = () => {
  const [orderId, setOrderId] = useState("");
  const { setMessageCurrent } = useMessages();
  const handleQuery = async (e) => {
    e.preventDefault();
    await firebase
      .firestore()
      .collection("orders")
      .doc(orderId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data.status == "neworder") {
            setMessageCurrent({
              title: [
                "Siparişiniz henuz kargoya verilmedi.",
                "Ihre Bestellung ist noch nicht versandt worden.",
                "Your order hasn't been shipped yet.",
              ],
              message: ["", "", ""],
              delivered: false,
            });
          } else if (data.status == "shipped") {
            setMessageCurrent({
              title: [
                "Siparişiniz kargoya verildi.",
                "Ihre Bestellung wurde versandt.",
                "Your order has been shipped.",
              ],
              message: [
                data.shippingNumber + " | "+data.address.shippingCompany,
                data.shippingNumber + " | "+data.address.shippingCompany,
                data.shippingNumber + " | "+data.address.shippingCompany,
              ],
              delivered: false,
            });
          }
        } else {
          setMessageCurrent({
            title: [
              "Sipariş bulunamadı",
              "Bestellung nicht gefunden",
              "Order not found",
            ],
            message: ["", "", ""],
            delivered: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        setMessageCurrent({
          title: [
            "Bir hata oldu..",
            "Es liegt ein Irrtum vor..",
            "there's been a mistake..",
          ],
          message: ["", "", ""],
          delivered: false,
        });
      });
    
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ mb: 4,minHeight:"90vh" }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography sx={{ mb: 3 }}>
            Learn shipping number with order number..
          </Typography>
          <Paper
            component="form"
            onSubmit={handleQuery}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter order number"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              inputProps={{
                "aria-label": "order number",
              }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              type="submit"
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
        </Paper>
      </Container>
    </>
  );
};

export default QueryShippingNumber;
