import { Link } from "react-router-dom";
import styled from "styled-components";


export const FooterContainer = styled.footer`
  background: #f5f5f7;
  margin-top: 20px;
  
`;
export const FooterWrapper = styled.div`
  padding: 20px 0px;
  .title {
    font-size: 15px;
    margin-bottom: 8px;
    padding-bottom: 7px;
    border-bottom: 1px solid #d2d2d7;
  }
  .legal {
    display: flex;
    flex-wrap: wrap;
    .copy {
      margin: 0;
      font-size: 12px;
      margin-right: 30px;
      margin-top: 5px;
      color: #6e6e73;
    }
    @media only screen and (max-width: 734px) {
      .copy {
        width: 100%;
      }
    }
  }
`;

export const FooterLink = styled(Link)`
  color: #424245;
  font-size: 12px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:not(:last-child){
    border-right: 1px solid #d2d2d7;
  }
  
  margin-right: 7px;
  padding-right: 10px;
  display: inline-block;
  margin-top: 5px;
  white-space: nowrap;
`;
