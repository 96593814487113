import { CardContent, Container, Typography } from "@mui/material";
import React from "react";
import CardAppl from "../../components/CardAppl";
import { useLanguage } from "../../contexts/Language";
import { useMessages } from "../../contexts/Messages";

const Messages = () => {
  const { languageIndex } = useLanguage();
  const { allMessages } = useMessages();
  return (
    <Container sx={{minHeight:"90vh"}}>
      <Typography variant="h3" style={{margin:"40px auto 10px auto",width:"fit-content",textAlign:"center"}}>{["Mesajlar","Nachrichten","Messages"][languageIndex]}</Typography>
      <Typography style={{margin:"0px auto 40px auto",width:"fit-content",maxWidth:"700px",textAlign:"center"}}>{["Burada bir sipariş verdiğinizde aldığınız bildirimleri göreceksiniz. Kargo numarası ve sipariş detaylarına buradan ulaşabilirsiniz.","Hier sehen Sie die Benachrichtigungen, die Sie erhalten, wenn Sie eine Bestellung aufgeben. Versandnummer und Bestelldetails sind hier verfügbar.","Here you will see the notifications you receive when you place an order. Shipping number and order details are available here."][languageIndex]}</Typography>
      {allMessages.map((message) => (
        <CardAppl>
          <CardContent>
            <Typography variant="h5">{message.title[languageIndex]}</Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {message.message[languageIndex]}
            </Typography>
          </CardContent>
        </CardAppl>
      ))}
    </Container>
  );
};

export default Messages;
