import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PagesWrapper from "../../../components/PagesWrapper";
import { useAuth } from "../../../contexts/Auth";
import { useOrders } from "../../../contexts/Orders";

var formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const Order = ({
  shippingPrice,
  shippingDiscount,
  productDiscount,
  address,
  orderId,
  status,
  time,
  sepet,
  totalPrice,
}) => {
  const shippingPriceIndirim =
    ((shippingPrice ? shippingPrice : 0) * shippingDiscount) / 100;
  const toplamProductFiyatIndirim =
    (((totalPrice ? totalPrice : 0) - (shippingPrice ? shippingPrice : 0)) *
      productDiscount) /
    100;
  const navigate = useNavigate();
  return (
    <Card sx={{ minWidth: 275, height: "100%" }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {address.uName + " " + address.lName + " | " + time}
        </Typography>
        <Typography variant="h5" component="div">
          {orderId}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {status}
        </Typography>
        <Typography gutterBottom variant="body2">
          Street: {address.street}
        </Typography>
        <Typography gutterBottom variant="body2">
          House Number: {address.addressline2 ? address.addressline2 + "" : ""}
        </Typography>
        <Typography gutterBottom variant="body2">
          Zip,City,Country: {address.zip + " " + address.city + " " + address.country}
        </Typography>
        <Typography gutterBottom>{address.phone}</Typography>

        <Typography variant="body2">
          {shippingPriceIndirim == 0 || toplamProductFiyatIndirim == 0 ? (
            formatter.format(totalPrice)
          ) : (
            <>
              <del>{formatter.format(totalPrice)}</del>{" "}
              {formatter.format(
                totalPrice - shippingPriceIndirim - toplamProductFiyatIndirim
              )}
            </>
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => navigate("/admin/order/" + orderId)}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};

const AdminShippedOrders = () => {
  const { shippedOrders } = useOrders();

  return (
    <PagesWrapper>
      <Grid container alignItems="stretch" spacing={3}>
        {shippedOrders.map((item) => (
          <Grid item xs={12} md={4}>
            <Order
              address={item.address}
              orderId={item.orderId}
              status={item.status}
              time={item.time}
              sepet={item.sepet}
              totalPrice={item.totalPrice}
              shippingPrice={item.shippingPrice}
              shippingDiscount={
                item.shippingDiscount ? item.shippingDiscount : 0
              }
              productDiscount={item.productDiscount ? item.productDiscount : 0}
            />
          </Grid>
        ))}
      </Grid>
    </PagesWrapper>
  );
};

export default AdminShippedOrders;
