import React from "react";
import { Card, Dialog, Box, Typography, Button, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@mui/material";
import { useMessages } from "../contexts/Messages";

import { Link } from "react-router-dom";
import { useLanguage } from "../contexts/Language";

const MessageDialog = () => {
  const { languageIndex } = useLanguage();
  const { messageCurrent, messageDelivered } = useMessages();
  const handleClose = () => {
    messageDelivered(messageCurrent.messageId);
  };
  return (
    <Dialog open={!!messageCurrent}>
      {messageCurrent ? (
        <>
          <DialogTitle id="alert-dialog-title">
            {messageCurrent.title[languageIndex]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {messageCurrent.message[languageIndex]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ float: "right" }}>
              Tamam
            </Button>
          </DialogActions>
        </>
      ) : undefined}
    </Dialog>
  );
};

export default MessageDialog;
