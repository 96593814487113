
//dhl de hollanda yok
//ispanyada farkli fiyatlar var, koymadim
const kargo = [
    {
        "ulke":"Germany",
        "code":"DE"
    },
    {
        "ulke":"Netherlands",
        "code":"NL"
    },
    {
        "ulke":"Austria",
        "code":"AT"
    },
    {
        "ulke":"Belgium",
        "code":"BE"
    },
    {
        "ulke":"Czech Republic",
        "code":"CZ"
    },
    {
        "ulke":"Denmark",
        "code":"DK"
    },
    {
        "ulke":"Estonia",
        "code":"EE"
    },
    {
        "ulke":"Finland",
        "code":"FI"
    },
    {
        "ulke":"France",
        "code":"FR"
    },
    {
        "ulke":"Croatia",
        "code":"HR"
    },
    {
        "ulke":"Ireland",
        "code":"IE"
    },
    {
        "ulke":"Sweden",
        "code":"SE"
    },
    {
        "ulke":"Italy",
        "code":"IT"
    },
    {
        "ulke":"Latvia",
        "code":"LV"
    },
    {
        "ulke":"Lithuania",
        "code":"LT"
    },
    {
        "ulke":"Luxembourg",
        "code":"LU"
    },
    {
        "ulke":"Hungary",
        "code":"HU"
    },
    {
        "ulke":"Poland",
        "code":"PL"
    },
    {
        "ulke":"Portugal",
        "code":"PT"
    },
    {
        "ulke":"Romania",
        "code":"RO"
    }
]
module.exports=kargo