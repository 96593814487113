import { Typography } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.section`
  width: 90%;
  margin: auto;
`;

export const VideoWrapper = styled.div`
    height: 35vw;
    overflow: hidden;
    width: 90vw;
    
`
export const Video = styled.video`
    width: 100%;
    position: relative;
    bottom: 7.8vw;
`

export const ProductWrapper = styled.div`

`
export const DialogImage = styled.img`
  width: 80%;
  max-width: 500px;
  margin: auto;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @media only screen and (max-width: 500px) {
    button {
      font-size: 10px;
      line-height: 1.2;
    }
  }
  
`
