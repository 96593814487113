import React, { useEffect } from "react";

import PagesWrapper from "../../../components/PagesWrapper";



const AdminHome = () => {
  return (
    <PagesWrapper>
    </PagesWrapper>
  );
};

export default AdminHome;
