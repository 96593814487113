import React, { useContext, createContext, useState, useEffect } from "react";
const AddressContext = createContext();

const AddressContextProvider = ({ children }) => {
  const [address, setAddress] = useState(null);
  const [shippingDiscount, setShippingDiscount] = useState(0)
  useEffect(() => {
    if (address) localStorage.setItem("address", JSON.stringify(address));
  }, [address]);

  useEffect(() => {
    const addressFromStorage = JSON.parse(localStorage.getItem("address"));
    if (addressFromStorage) {
      setAddress(addressFromStorage);
    }
  }, []);

  return (
    <AddressContext.Provider value={{ address, setAddress, shippingDiscount, setShippingDiscount }}>
      {children}
    </AddressContext.Provider>
  );
};

export const useAddress = () => useContext(AddressContext);

export default AddressContextProvider;
