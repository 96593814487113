import { IconButton } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import Home from "./pages/home/Home";
import { AiOutlineClose } from "react-icons/ai";
import SepetContextProvider from "./contexts/Sepet";
import AddressContextProvider from "./contexts/Address";
import AppRouter from "./router/AppRouter";
import AuthContextProvider from "./contexts/Auth";
import LanguageContextProvider from "./contexts/Language";
import MessagesContextProvider from "./contexts/Messages";
import OrdersContextProvider from "./contexts/Orders";
import AdminMessagesContextProvider from "./contexts/AdminMessages";

function App() {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={1600}
        ref={notistackRef}
        action={(key) => (
          <IconButton
            size="small"
            sx={{ color: "white" }}
            onClick={onClickDismiss(key)}
            aria-label="delete"
          >
            <AiOutlineClose />
          </IconButton>
        )}
      >
        <AuthContextProvider>
          <MessagesContextProvider>
            <AdminMessagesContextProvider>
              <LanguageContextProvider>
                <SepetContextProvider>
                  <AddressContextProvider>
                    <OrdersContextProvider>
                      <AppRouter />
                    </OrdersContextProvider>
                  </AddressContextProvider>
                </SepetContextProvider>
              </LanguageContextProvider>
            </AdminMessagesContextProvider>
          </MessagesContextProvider>
        </AuthContextProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
