import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../contexts/Address";
import { useLanguage } from "../../contexts/Language";
import kargo from "./kargo";

const Address = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { language, languageIndex } = useLanguage();
  const { address, setAddress } = useAddress();
  const navigate = useNavigate();
  const [uName, setUName] = useState("");
  const handleNameChange = (event) => {
    setUName(event.target.value);
  };
  const [lName, setLName] = useState("");
  const handleLNameChange = (event) => {
    setLName(event.target.value);
  };
  const [email, setEmail] = useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const [phone, setPhone] = useState("");
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const [street, setStreet] = useState("");
  const handleStreetChange = (event) => {
    setStreet(event.target.value);
  };
  const [addressline2, setAddressline2] = useState("");
  const handleAddressline2Change = (event) => {
    setAddressline2(event.target.value);
  };
  console.log("addres==>",addressline2);
  const [city, setCity] = useState("");
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };
  const [state, setState] = useState("");
  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const [zip, setZip] = useState("");
  const handleZipChange = (event) => {
    setZip(event.target.value);
  };
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const countries = kargo.map((v) => [v.ulke, v.code]);
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setCountryCode(
      countries.filter((item) => item[0] == event.target.value)[0][1]
    );
  };
  const [shippingCompany, setShippingCompany] = useState("");
  const handleShippingCompanyChange = (event) => {
    setShippingCompany(event.target.value);
  };

  useEffect(() => {
    if (address) {
      if (address.uName != "" && address.uName != undefined)
        setUName(address.uName);
      if (address.lName != "" && address.lName != undefined)
        setLName(address.lName);
      if (address.phone != "" && address.phone != undefined)
        setPhone(address.phone);
      if (address.email != "" && address.email != undefined)
        setEmail(address.email);
      if (address.street != "" && address.street != undefined)
        setStreet(address.street);
      if (address.addressline2 != "" && address.addressline2 != undefined)
        setAddressline2(address.addressline2);
      if (address.city != "" && address.city != undefined)
        setCity(address.city);
      if (address.state != "" && address.state != undefined)
        setState(address.state);
      if (address.zip != "" && address.zip != undefined) setZip(address.zip);
      if (address.country != "" && address.country != undefined) {
        setCountry(address.country);
        setCountryCode(address.countryCode);
      }

      if (address.shippingCompany != "" && address.shippingCompany != undefined)
        setShippingCompany(address.shippingCompany);
    }
  }, [address]);

  const handleNext = () => {

    console.log(uName.length > 2 ,
      lName.length > 1 ,
      phone.length > 2 ,
      email.length > 2 ,
      street.length > 2 ,
      addressline2.length > 0 ,
      city.length > 2 ,
      zip.length > 2 ,
      country ,
      shippingCompany)
    
    if (
      uName.length > 2 &&
      lName.length > 0 &&
      phone.length > 2 &&
      email.length > 2 &&
      street.length > 2 &&
      addressline2.length > 0 &&
      city.length > 2 &&
      zip.length > 2 &&
      country &&
      shippingCompany
      
    ) {
      setAddress({
        uName,
        lName,
        phone,
        email,
        street,
        addressline2,
        city,
        state,
        zip,
        country,
        countryCode,
        shippingCompany,
      });
      navigate("/review");
    } else {
      enqueueSnackbar("please fill all required fields!", {
        variant: "warning",
      });
    }
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4, minHeight:"90vh" }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Stepper activeStep={1} alternativeLabel sx={{ pt: 3, pb: 5 }}>
            <Step>
              <StepLabel onClick={() => navigate("/sepet")}>
                {language === "tr" && "Sepet"}
                {language === "de" && "Warenkorb"}
                {language === "en" && "Shopping Cart"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {language === "tr" && "Adres"}
                {language === "de" && "Adresse"}
                {language === "en" && "Address"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel onClick={handleNext}>
                {language === "tr" && "Gözden geçir"}
                {language === "de" && "Überprüfung"}
                {language === "en" && "Review"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {language === "tr" && "Ödeme"}
                {language === "de" && "Zahlung"}
                {language === "en" && "Payment"}
              </StepLabel>
            </Step>
          </Stepper>
          <Typography variant="h6" gutterBottom>
            {language === "tr" && "Gönderi adresi"}
            {language === "de" && "Lieferadresse"}
            {language === "en" && "Shipping address"}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstname"
                name="firstname"
                label="First Name"
                autoComplete="given-name"
                value={uName}
                onChange={handleNameChange}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastname"
                name="lastname"
                label="Last Name"
                autoComplete="family-name"
                value={lName}
                onChange={handleLNameChange}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="phone"
                name="phone"
                label="Phone"
                value={phone}
                onChange={handlePhoneChange}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="street"
                name="street"
                label="Street address"
                value={street}
                onChange={handleStreetChange}
                fullWidth
                autoComplete="shipping address-line1"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="home number"
                label="Apt., ste., bldg., home number"
                autoComplete="shipping address-line2"
                value={addressline2}
                onChange={handleAddressline2Change}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                value={city}
                autoComplete="shipping address-level2"
                onChange={handleCityChange}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="zip"
                name="zip"
                label="Zip / Postal code"
                autoComplete="shipping postal-code"
                fullWidth
                value={zip}
                onChange={handleZipChange}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 120, width: "100%" }}
                >
                  <InputLabel>Country</InputLabel>
                  <Select
                    autoComplete="shipping country"
                    required
                    id="country"
                    value={country}
                    label="Country"
                    onChange={handleCountryChange}
                  >
                    {countries.map((v) => {
                      return <MenuItem value={v[0]}>{v[0]}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group" required>
                  Shipping company
                </FormLabel>
                <RadioGroup
                  required
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row
                  value={shippingCompany}
                  onChange={handleShippingCompanyChange}
                >
                  <FormControlLabel
                    value="dhl"
                    control={<Radio />}
                    label="DHL"
                  />
                  <FormControlLabel
                    value="hermes"
                    control={<Radio />}
                    label="Hermes"
                  />
                  <FormControlLabel
                    value="express"
                    control={<Radio />}
                    label="DHL Express"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox color="secondary" name="saveAddress" value="yes" />
                }
                label="Use this address for payment details"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => navigate("/sepet")} sx={{ mt: 3, ml: 1 }}>
              {language === "tr" && "Geri"}
              {language === "de" && "Zurück"}
              {language === "en" && "Back"}
            </Button>

            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
            >
              {language === "tr" && "İleri"}
              {language === "de" && "Nächste"}
              {language === "en" && "Next"}
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Address;
