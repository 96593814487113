import {
  Box,
  Button,
  ButtonGroup,
  ListItemIcon,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  BadgeAppl,
  CusAppBar,
  LanguageButton,
  LanguageButtons,
  NavButton,
} from "./Header.mui.style";

import {
  AiOutlineInstagram,
  AiFillShopping,
  AiFillPhone,
  AiFillNotification,
  AiOutlineHistory,
  AiOutlineLogout,
} from "react-icons/ai";
import { MdAccountCircle } from "react-icons/md";
import { useAuth } from "../../contexts/Auth";

import logo from "./media/logo.png";
import { useSepet } from "../../contexts/Sepet";
import { useLanguage } from "../../contexts/Language";
import { useMessages } from "../../contexts/Messages";



function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = (props) => {
  const { signOutUser, user } = useAuth();
  const { sepet } = useSepet();
  const { setLanguage, setLanguageIndex, languageIndex } = useLanguage();
  const {messages} = useMessages()
  const handleLanguageChange = (index) => {
    if (index == 0) {
      setLanguage("tr");
      setLanguageIndex(index);
    } else if (index == 1) {
      setLanguage("de");
      setLanguageIndex(index);
    } else if (index == 2) {
      setLanguage("en");
      setLanguageIndex(index);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSignInClick = () => {
    console.log("sign in");
  };
  return (
    <HideOnScroll {...props}>
      <CusAppBar position="sticky">
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Link to="/">
              <img src={logo} alt="" style={{ width: "150px" }} />
            </Link>
          </Box>
          <LanguageButtons size="small" aria-label="small button group">
            <LanguageButton onClick={()=>handleLanguageChange(0)} sx={languageIndex==0? { color: "green !important" } : {}} key="one">
              Tr
            </LanguageButton>
            <LanguageButton onClick={()=>handleLanguageChange(1)} sx={languageIndex==1? { color: "green !important" } : {}} key="two">De</LanguageButton>
            <LanguageButton onClick={()=>handleLanguageChange(2)} sx={languageIndex==2? { color: "green !important" } : {}} key="three">En</LanguageButton>
          </LanguageButtons>
          {/* <NavButton
            size="large"
            aria-label="instagram"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => window.open("https://www.instagram.com/cigkoftede/")}
            sx={{ ml: 1, p: 2, width: 20, height: 20 }}
          >
            <BadgeAppl badgeContent={0}>
              <AiOutlineInstagram fontSize={"24px"} />
            </BadgeAppl>
          </NavButton> */}
          <NavButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            component={Link}
            to="/sepet"
            sx={{ ml: 1, p: 2, width: 20, height: 20 }}
          >
            <BadgeAppl badgeContent={sepet.length}>
              <AiFillShopping fontSize={"24px"} />
            </BadgeAppl>
          </NavButton>

          <NavButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            component={Link}
            to="/messages"
            sx={{ ml: 1, p: 2, width: 20, height: 20 }}
          >
            <BadgeAppl badgeContent={messages.length}>
              <AiFillNotification fontSize="24px" />
            </BadgeAppl>
          </NavButton>
        </Toolbar>
      </CusAppBar>
    </HideOnScroll>
  );
};

export default Header;
