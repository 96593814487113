import {
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../contexts/Address";
import { useLanguage } from "../../contexts/Language";
import { useSepet } from "../../contexts/Sepet";
import { toplamHesapla } from "../../utils/toplamHesapla";

import firebase from "../../utils/firebase";
import { useMessages } from "../../contexts/Messages";

var formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const Review = () => {
  const { setMessageCurrent } = useMessages();
  const { language, languageIndex } = useLanguage();
  const { sepet, deleteFromSepet, productDiscount, setProductDiscount } =
    useSepet();
  const { address, setAddress, shippingDiscount, setShippingDiscount } =
    useAddress();

  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/payment");
  };
  const kgsDhl = [2, 5, 10, 20];
  const pricesDhl = [14, 16, 21, 32];
  const [shippingPrice, setShippingPrice] = useState(0);
  const [toplamKg, setToplamKg] = useState(0);
  const [toplamFiyat, setToplamFiyat] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const handleCouponCodeChange = (e) => {
    let txt = "" + e.target.value;
    setCouponCode(txt.toUpperCase());
  };
  const applyDiscount = (product_discount, shipping_discount) => {
    setProductDiscount(product_discount);
    setShippingDiscount(shipping_discount);
  };
  const handleCouponCodeSubmit = async (e) => {
    e.preventDefault();
    console.log(couponCode);
    await firebase
      .firestore()
      .collection("coupons")
      .doc(couponCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data.remainingQuantity > 0) {
            applyDiscount(data.productDiscount, data.shippingDiscount);
            firebase
              .firestore()
              .collection("coupons")
              .doc(couponCode)
              .update({
                remainingQuantity: firebase.firestore.FieldValue.increment(-1),
              });
          } else {
            setMessageCurrent({
              title: [
                "Kupon bulunamadı",
                "Kein Gutschein gefunden",
                "Coupon not found",
              ],
              message: ["", "", ""],
              delivered: false,
            });
          }
        } else {
          setMessageCurrent({
            title: [
              "Kupon bulunamadı",
              "Kein Gutschein gefunden",
              "Coupon not found",
            ],
            message: ["", "", ""],
            delivered: false,
          });
        }
      });
  };

  useEffect(() => {
    if (address && sepet) {
      let toplam = toplamHesapla(sepet, address);
      setToplamKg(toplam[0]);
      setToplamFiyat(toplam[1]);
      setShippingPrice(toplam[2]);
    }
  }, [sepet, address]);

  const toplamFiyatHesaplaIndirimlerile = () => {
    let shippingPriceIndirim = (shippingPrice * shippingDiscount) / 100;
    let toplamProductFiyatIndirim =
      ((toplamFiyat - shippingPrice) * productDiscount) / 100;
    return toplamFiyat - shippingPriceIndirim - toplamProductFiyatIndirim;
  };

  return (
    <>
      <CssBaseline />
      <Container
        component="main"
        maxWidth="sm"
        sx={{ mb: 4, minHeight: "90vh" }}
      >
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Stepper activeStep={2} alternativeLabel sx={{ pt: 3, pb: 5 }}>
            <Step>
              <StepLabel onClick={() => navigate("/sepet")}>
                {language === "tr" && "Sepet"}
                {language === "de" && "Warenkorb"}
                {language === "en" && "Shopping Cart"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel onClick={() => navigate("/address")}>
                {language === "tr" && "Adres"}
                {language === "de" && "Adresse"}
                {language === "en" && "Address"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {language === "tr" && "Gözden geçir"}
                {language === "de" && "Überprüfung"}
                {language === "en" && "Review"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {language === "tr" && "Ödeme"}
                {language === "de" && "Zahlung"}
                {language === "en" && "Payment"}
              </StepLabel>
            </Step>
          </Stepper>

          <List disablePadding>
            {sepet.map((product) => (
              <ListItem key={Math.random()} sx={{ py: 1, px: 0 }}>
                <ListItemText
                  primary={product.title[languageIndex]}
                  secondary={
                    product.adet + [" Adet", " Stück", " Pieces"][languageIndex]
                  }
                />
                <Typography variant="body2">
                  {formatter.format(product.price * product.adet)}
                </Typography>
              </ListItem>
            ))}
            <ListItem sx={{ py: 1, px: 0 }}>
              <ListItemText primary={"Shipping"} secondary={toplamKg + " kg"} />
              <Typography variant="body2">
                {shippingDiscount > 0 ? (
                  <>
                    <del>{formatter.format(shippingPrice)}</del>{" "}
                    {formatter.format(
                      shippingPrice - (shippingPrice * shippingDiscount) / 100
                    )}
                  </>
                ) : (
                  formatter.format(shippingPrice)
                )}
              </Typography>
            </ListItem>

            <ListItem sx={{ py: 1, px: 0 }}>
              <ListItemText primary="Total" />
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                {shippingDiscount || productDiscount ? (
                  <>
                    <del>{formatter.format(toplamFiyat)}</del>{" "}
                    {formatter.format(toplamFiyatHesaplaIndirimlerile())}
                  </>
                ) : (
                  formatter.format(toplamFiyat)
                )}
              </Typography>
            </ListItem>
            <ListItem sx={{ py: 1, px: 0 }}>
              <ListItemText primary="" />
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 400, fontSize: "14px" }}
              >
                {language == "tr" && "KDV dahil artı nakliye"}
                {language == "de" && "inkl. MwSt. zzgl. Versand"}
                {language == "en" && "incl. VAT plus shipping"}
              </Typography>
            </ListItem>
          </List>
          <Grid
            container
            sx={{ mt: 2 }}
            component="form"
            onSubmit={handleCouponCodeSubmit}
          >
            <Grid item sm={9}>
              <TextField
                id="couponCode"
                name="couponCode"
                label="Coupon Code"
                fullWidth
                value={couponCode}
                inputProps={{ style: { textTransform: "uppercase" } }}
                variant="standard"
                onChange={handleCouponCodeChange}
              />
            </Grid>
            <Grid
              item
              sm={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Button type="submit">
                {language === "tr" && "Uygula"}
                {language === "de" && "Anwenden"}
                {language === "en" && "Apply"}
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                {language === "tr" && "Gönderi adresi"}
                {language === "de" && "Lieferadresse"}
                {language === "en" && "Shipping address"}
              </Typography>
              {address ? (
                <>
                  <Typography gutterBottom>
                    {address && address.uName + " " + address.lName}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Street: {address.street}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Apt., ste., bldg., home number:{" "}
                    {address.addressline2 ? address.addressline2 + "" : ""}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Zip,City,Country:{" "}
                    {address.zip + " " + address.city + " " + address.country}
                  </Typography>
                  <Typography gutterBottom>
                    {address && address.phone}
                  </Typography>
                  <Typography gutterBottom>
                    {address && address.email}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => navigate("/address")} sx={{ mt: 3, ml: 1 }}>
              {language === "tr" && "Geri"}
              {language === "de" && "Zurück"}
              {language === "en" && "Back"}
            </Button>

            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
            >
              {language === "tr" && "İleri"}
              {language === "de" && "Nächste"}
              {language === "en" && "Next"}
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Review;
