export const products = [
  {
    title: [
      "VAKUMLU NORMAL ACILI ÇİGKÖFTE 500g",
      "VAKUUM MITTELSCHARFE ÇİGKÖFTE 500g",
      "VAKUUM ÇİGKÖFTE 500g",
    ],
    id: 0,
    price: 10,
    weight: 0.5,
  },
  {
    title: ["VAKUMLU NORMAL ACILI ÇİGKÖFTE 1kg", "VAKUUM MITTELSCHARFE ÇİGKÖFTE 1kg", "VAKUUM ÇİGKÖFTE 1kg"],
    id: 1,
    price: 18,
    weight: 1,
  },
  {
    title: ["VAKUMLU NORMAL ACILI ÇİGKÖFTE 2kg", "VAKUUM MITTELSCHARFE ÇİGKÖFTE 2kg", "VAKUUM ÇİGKÖFTE 2kg"],
    id: 2,
    price: 32,
    weight: 2,
  },
  {
    title: ["VAKUMLU NORMAL ACILI ÇİGKÖFTE 5kg", "VAKUUM MITTELSCHARFE ÇİGKÖFTE 5kg", "VAKUUM ÇİGKÖFTE 5kg"],
    id: 3,
    price: 65,
    weight: 5,
  },
  {
    title: [
      "VAKUMLU NORMAL ACILI ÇİGKÖFTE 10kg",
      "VAKUUM MITTELSCHARFE ÇİGKÖFTE 10kg",
      "VAKUUM ÇİGKÖFTE 10kg",
    ],
    id: 4,
    price: 100,
    weight: 10,
  },
  {
    title: [
      "VAKUM ACILI ÇİGKÖFTE 500g",
      "VAKUUM SCHÄRFE ÇİGKÖFTE 500g",
      "VAKUUM HOT ÇİGKÖFTE 500g",
    ],
    id: 5,
    price: 11,
    weight: 0.5,
  },
  {
    title: [
      "VAKUM ACILI ÇİGKÖFTE 1000g",
      "VAKUUM SCHÄRFE ÇİGKÖFTE 1000g",
      "VAKUUM HOT ÇİGKÖFTE 1000g",
    ],
    id: 6,
    price: 20,
    weight: 1,
  },
  {
    title: [
      "VAKUM ACILI ÇİGKÖFTE 2kg",
      "VAKUUM SCHÄRFE ÇİGKÖFTE 2kg",
      "VAKUUM HOT ÇİGKÖFTE 2kg",
    ],
    id: 7,
    price: 34,
    weight: 2,
  },
  {
    title: [
      "VAKUM ACILI ÇİGKÖFTE 5kg",
      "VAKUUM SCHÄRFE ÇİGKÖFTE 5kg",
      "VAKUUM HOT ÇİGKÖFTE 5kg",
    ],
    id: 8,
    price: 68,
    weight: 5,
  },
  {
    title: [
      "VAKUM ACILI ÇİGKÖFTE 10kg",
      "VAKUUM SCHÄRFE ÇİGKÖFTE 10kg",
      "VAKUUM HOT ÇİGKÖFTE 10kg",
    ],
    id: 9,
    price: 110,
    weight: 10,
  },
  {
    title: [
      "Şalgam Suyu 330ml",
      "ŞALGAM (Rübensaft pikant) 330ml",
      "Rutabaga Juice 330ml",
    ],
    id: 10,
    price: 3,
    weight: 0.5,
  },
  {
    title: [
      "Şalgam Suyu 330ml Hot",
      "ŞALGAM (Rübensaft pikant) 330ml Hot",
      "Rutabaga Juice 330ml Hot",
    ],
    id: 11,
    price: 3,
    weight: 0.5,
  },
  {
    title: [
      "Şalgam Suyu 1lt",
      "ŞALGAM (Rübensaft pikant) 1lt",
      "Rutabaga Juice 1lt",
    ],
    id: 12,
    price: 6,
    weight: 1,
  },
  {
    title: [
      "Şalgam Suyu 1lt Hot",
      "ŞALGAM (Rübensaft pikant) 1lt Hot",
      "Rutabaga Juice 1lt Hot",
    ],
    id: 13,
    price: 6,
    weight: 1,
  },

  {
    title: ["NAR EKŞİSİ 1000gr", "Granatapfelsose 1000gr", "Pomegranate Syrup 1000gr"],
    id: 14,
    price: 5.5,
    weight: 1,
  },
  {
    title: [
      "Cubuk Salatalik Tursu 620gr",
      "Selin pickled wild cucumbers 620gr",
      "Selin eingelegte Wildgurken 620gr",
    ],
    id: 15,
    price: 3.9,
    weight: 0.7,
  },
  {
    title: [
      "Cubuk Salatalik Tursu 1600gr",
      "Selin pickled wild cucumbers 1600gr",
      "Selin eingelegte Wildgurken 1600gr",
    ],
    id: 16,
    price: 6.9,
    weight: 1.6,
  },
  {
    title: [
      "Cubuk Salatalik Tursu 2500gr",
      "Selin pickled wild cucumbers 2500gr",
      "Selin eingelegte Wildgurken 2500gr",
    ],
    id: 17,
    price: 8.9,
    weight: 2.5,
  },
  {
    title: [
      "Cubuk Salatalik Tursu 5kg",
      "Selin pickled wild cucumbers 5kg",
      "Selin eingelegte Wildgurken 5kg",
    ],
    id: 18,
    price: 14,
    weight: 5,
  },
  {
    title: [
      "Selin Jalapeno Biber Tursu 580gr",
      "Selin eingelegte Jalapeno 580gr",
      "Selin pickled jalapeno 580gr",
    ],
    id: 19,
    price: 3.9,
    weight: 0.58,
  },
  {
    title: [
      "Selin Jalapeno Biber Tursu 1500gr",
      "Selin eingelegte Jalapeno 1500gr",
      "Selin pickled jalapeno 1500gr",
    ],
    id: 20,
    price: 6.9,
    weight: 1.5,
  },
  {
    title: [
      "Melis Karisik Tursu 670gr",
      "Melis Mischgemüse in Salzlake 670gr",
      "Melis Mixed vegetables in brine 670gr",
    ],
    id: 21,
    price: 3.90,
    weight: 0.7,
  },
  {
    title: [
      "Melis Karisik Tursu 2500gr",
      "Melis Mischgemüse in Salzlake 2500gr",
      "Melis Mixed vegetables in brine 2500gr",
    ],
    id: 22,
    price: 8.90,
    weight: 2.5,
  },
  {
    title: [
      "Melis Karisik Tursu 5000gr",
      "Melis Mischgemüse in Salzlake 5000gr",
      "Melis Mixed vegetables in brine 5000gr",
    ],
    id: 23,
    price: 14,
    weight: 5,
  },
  {
    title: [
      "Ece Sriracha Super Chili Aci Biber Sos 660gr",
      "Ece Sriracha Super Chili Sose 660gr",
      "Ece Sriracha Super Chili Sauce 660gr",
    ],
    id: 24,
    price: 3.99,
    weight: 0.7,
  },
  {
    title: [
      "Ece Sambal Oelek Aci Biber Sos 660gr",
      "Ece Sambal Oelek Sose 660gr",
      "Ece Sambal Oelek Sauce 660gr",
    ],
    id: 25,
    price: 3.99,
    weight: 0.7,
  },
  {
    title: [
      "Ece Fijne Chili Tatli Biber Sos 660gr",
      "Ece Fijne Chili Sauce 660gr",
      "Ece Fijne Chili Sauce 660gr",
    ],
    id: 26,
    price: 3.99,
    weight: 0.7,
  },
  {
    title: [
      "Ece Alaturka Sarimsakli Sos 660gr",
      "Ece Alaturka Garlic Sose 660gr",
      "Ece Alaturka Garlic Sauce 660gr",
    ],
    id: 27,
    price: 3.99,
    weight: 0.7,
  },
  {
    title: [
      "Ece Thai Sarimsakli Sos 500gr",
      "Ece Thai Garlic Sose 500gr",
      "Ece Thai Garlic Sauce 500gr",
    ],
    id: 28,
    price: 3.99,
    weight: 0.7,
  },
  {
    title: [
      "VAKUMLU AZ ACILI ÇİGKÖFTE 500g",
      "VAKUUM WENIGER SCHARFE ÇİGKÖFTE 500g",
      "VAKUUM LOWSPICY ÇİGKÖFTE 500g",
    ],
    id: 29,
    price: 10,
    weight: 0.5,
  },
  {
    title: ["VAKUMLU AZ ACILI ÇİGKÖFTE 1kg", "VAKUUM WENIGER SCHARFE ÇİGKÖFTE 1kg", "VAKUUM LOWSPICY ÇİGKÖFTE 1kg"],
    id: 30,
    price: 18,
    weight: 1,
  },
  {
    title: ["VAKUMLU AZ ACILI ÇİGKÖFTE 2kg", "VAKUUM WENIGER SCHARFE ÇİGKÖFTE 2kg", "VAKUUM LOWSPICY ÇİGKÖFTE 2kg"],
    id: 31,
    price: 32,
    weight: 2,
  },
  {
    title: ["VAKUMLU AZ ACILI ÇİGKÖFTE 5kg", "VAKUUM WENIGER SCHARFE ÇİGKÖFTE 5kg", "VAKUUM LOWSPICY ÇİGKÖFTE 5kg"],
    id: 32,
    price: 65,
    weight: 5,
  },
  {
    title: [
      "VAKUMLU AZ ACILI ÇİGKÖFTE ÇİGKÖFTE 10kg",
      "VAKUUM WENIGER SCHARFE ÇİGKÖFTE 10kg",
      "VAKUUM LOWSPICY ÇİGKÖFTE 10kg",
    ],
    id: 33,
    price: 100,
    weight: 10,
  },
];
