import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../contexts/Address";
import { useAuth } from "../../contexts/Auth";
import { useLanguage } from "../../contexts/Language";
import { useMessages } from "../../contexts/Messages";
import { useSepet } from "../../contexts/Sepet";
import firebase from "../../utils/firebase";
import { toplamHesapla } from "../../utils/toplamHesapla";
import { v4 as uuidv4 } from "uuid";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


function convertTZ() {
  var options = {
    hour: "2-digit",
    minute: "2-digit",
    month: "long",
    day: "numeric",
    timeZone: "Europe/Berlin",
  };
  return new Date().toLocaleString("de-DE", options);
}

const message = (totalPrice, orderId, type) => {
  let havale = [
    `Havale ile ödeme yaptığınızı onayladığımız zaman sipariş kargoya verilicektir. Siparişiniz kargoya verildiği zaman kargo numaranız bu website üzerinden size mesaj olarak iletilecektir. Mesajlarınıza sağ üst taraftaki bildirimler bölümünden ulaşabilirsiniz veya sipariş durumunuzu kontrol etmek için https://cigkofteshop.com/queryshippingnumber adresinden formu doldurabilirsiniz. | Iban: TR1390132013131290 | Description: ${orderId} | Price: ${totalPrice} `,
    `Wenn wir bestätigen, dass Sie per Banküberweisung bezahlt haben, wird die Bestellung versandt. Wenn Ihre Bestellung versandt wird, wird Ihnen Ihre Frachtnummer als Nachricht über diese Website zugesandt. Sie können Ihre Nachrichten über den Bereich "Benachrichtigungen" oben rechts abrufen oder das Formular auf https://cigkofteshop.com/queryshippingnumber ausfüllen, um den Status Ihrer Bestellung zu überprüfen. | Iban: TR1390132013131290 | Description: ${orderId} | Price: ${totalPrice} `,
    `When we confirm that you have paid by wire transfer, the order will be shipped. When your order is shipped, your cargo number will be sent to you as a message on this website. You can access your messages from the notifications section at the top right or you can fill out the form at https://cigkofteshop.com/queryshippingnumber to check your order status. | Iban: TR1390132013131290 | Description: ${orderId} | Price: ${totalPrice} `,
  ];
  let paypal = [
    `Ödemeniz alındı. Siparişiniz kargoya verildiği zaman kargo numaranız bu website üzerinden size mesaj olarak iletilecektir. Mesajlarınıza sağ üst taraftaki bildirimler bölümünden ulaşabilirsiniz veya sipariş durumunuzu kontrol etmek için https://cigkofteshop.com/queryshippingnumber adresinden formu doldurabilirsiniz.`,
    `Ihre Zahlung ist eingegangen. Wenn Ihre Bestellung versandt wird, wird Ihnen Ihre Frachtnummer als Nachricht über diese Website zugesandt. Sie können Ihre Nachrichten über den Bereich "Benachrichtigungen" oben rechts abrufen oder das Formular auf https://cigkofteshop.com/queryshippingnumber ausfüllen, um den Status Ihrer Bestellung zu überprüfen.`,
    `Your payment has been received. When your order is shipped, your cargo number will be sent to you as a message on this website. You can access your messages from the notifications section at the top right or you can fill out the form at https://cigkofteshop.com/queryshippingnumber to check your order status.`,
  ];
  return {
    title: [
      "Siparişiniz Alındı | Sipariş numarası: " + orderId,
      "Ihre Bestellung ist eingegangen | Bestellnummer: " + orderId,
      "Your Order Received | Order number: " + orderId,
    ],
    message: type == "paypal" ? paypal : havale,
    delivered: false,
    messageId: uuidv4(),
  };
};
//AVXRa7CeDK8KUirmJp7ZnMH7lrxP7xV256Gen_MtjY5pxzxMsYFHd7YXvmqDhlZkW4SxaQidSS_N42eq
//https://developer.paypal.com/docs/api/orders/v2/#definition-purchase_unit_request
//https://developer.paypal.com/docs/api/orders/v2/#definition-shipping_detail
const Payment2 = () => {
  const navigate = useNavigate();
  const { setMessageCurrent } = useMessages();
  const { language, languageIndex } = useLanguage();
  const { user } = useAuth();
  const { sepet, deleteAllProducts, productDiscount } = useSepet();
  const { address, setAddress, shippingDiscount, setShippingDiscount} = useAddress();
  const [shippingPrice, setShippingPrice] = useState(0);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [weight, setWeight] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [productTotalPrice, setProductTotalPrice] = useState(0);
  const [orderId, setOrderId] = useState();
  
  useEffect(() => {
    //[toplam_kg, toplam_fiyat, shipping_price, product_total];
    if(address){
      let toplam = toplamHesapla(sepet,address);
      setWeight(toplam[0]);
      setTotalPrice(toplam[1]);
      setShippingPrice(toplam[2]);
      setProductTotalPrice(toplam[3]);
    }
    
  }, [sepet]);
  const saveOrderToDb = async (orderID, details) => {
    setOpenBackDrop(true);
    let order = {
      sepet: sepet,
      address: address,
      paypalDonenVeri: details,
      type: "paypal",
      orderId: orderID,
      totalPrice: totalPrice,
      shippingDiscount: shippingDiscount? shippingDiscount: 0,
      productDiscount: productDiscount?productDiscount: 0,
      shippingPrice: shippingPrice,
      weight: weight,
      uid: user.uid,
      timestamp: new Date().getTime(),
      time: convertTZ(),
      status: "neworder",
    };
    setOrderId(orderID);
    await firebase.firestore().collection("orders").doc(orderID).set(order);
    let m = message(totalPrice, orderID, order.type);
    await firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("appMessages")
      .doc(m.messageId)
      .set(m);
    deleteAllProducts();
    setShippingDiscount(0);
    setOpenBackDrop(false);
    navigate("/")
    console.log(order);
  };

  const fixDecimal = (number)=>{
    return Math.round(number * 100) / 100
  }
  
  function add(accumulator, a) {
    return accumulator + a;
  }
  const urunSayisi = sepet.map((item) => item.adet).reduce(add, 0);
  console.log(urunSayisi);

  return (
    <>
      <CssBaseline />
      <Container
        component="main"
        maxWidth="sm"
        sx={{ mb: 4, minHeight: "90vh" }}
      >
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          
          <Stepper activeStep={3} alternativeLabel sx={{ pt: 3, pb: 5 }}>
            <Step>
              <StepLabel onClick={() => navigate("/sepet")}>
                {language === "tr" && "Sepet"}
                {language === "de" && "Warenkorb"}
                {language === "en" && "Shopping Cart"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel onClick={() => navigate("/address")}>
                {language === "tr" && "Adres"}
                {language === "de" && "Adresse"}
                {language === "en" && "Address"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel onClick={() => navigate("/review")}>
                {language === "tr" && "Gözden geçir"}
                {language === "de" && "Überprüfung"}
                {language === "en" && "Review"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {language === "tr" && "Ödeme"}
                {language === "de" && "Zahlung"}
                {language === "en" && "Payment"}
              </StepLabel>
            </Step>
          </Stepper>
          {sepet.length > 0 && (
            <PayPalScriptProvider
              options={{
                "client-id":
                  "AVXRa7CeDK8KUirmJp7ZnMH7lrxP7xV256Gen_MtjY5pxzxMsYFHd7YXvmqDhlZkW4SxaQidSS_N42eq",
                currency: "EUR",
              }}
            >
              <PayPalButtons
                createOrder={(data, actions) => {
                  console.log("xx=>",shippingPrice, shippingDiscount, fixDecimal(shippingPrice*shippingDiscount/100))
                  console.log({
                    amount: {
                      currency_code: "EUR",
                      
                      value:  fixDecimal(totalPrice - fixDecimal((totalPrice - shippingPrice)*productDiscount/100)  -  fixDecimal(shippingPrice*shippingDiscount/100)) || urunSayisi * 0.01 + 0.01 ,
                      breakdown: {
                        item_total: {
                          currency_code: "EUR",
                          value:
                          fixDecimal(totalPrice - shippingPrice) || urunSayisi * 0.01 ,
                        },
                        shipping: {
                          currency_code: "EUR",
                          value:  shippingPrice || 0.01 ,
                        },
                        discount: {
                          currency_code: "EUR",
                          value:  fixDecimal(fixDecimal(totalPrice - shippingPrice)*productDiscount/100) || 0 ,
                        },
                        shipping_discount: {
                          currency_code: "EUR",
                          value:  fixDecimal(shippingPrice*shippingDiscount/100) || 0,
                        },
                      },
                    },
                    shipping: {
                      name: {
                        full_name: `${address.uName+" "+address.lName}`,
                      },
                      address: {
                        address_line_1: `${address.street}`,
                        address_line_1: `${address.addressline2 ? address.addressline2 : ""}`,
                        admin_area_2: `${address.city}`,
                        admin_area_1: `${address.state}`,
                        postal_code: `${address.zip}`,
                        country_code: `${address.countryCode}`,
                      },
                    },
                    items: sepet.map((item) => ({
                      name: item.title[2],
                      unit_amount: {
                        currency_code: "EUR",
                        value:  item.price || 0.01 ,
                      },
                      quantity: item.adet,
                      description: " ",
                    })),
                  })
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          currency_code: "EUR",
                          
                          value:  fixDecimal(totalPrice - fixDecimal((totalPrice - shippingPrice)*productDiscount/100)  -  fixDecimal(shippingPrice*shippingDiscount/100)) || urunSayisi * 0.01 + 0.01 ,
                          breakdown: {
                            item_total: {
                              currency_code: "EUR",
                              value:
                              fixDecimal(totalPrice - shippingPrice) || urunSayisi * 0.01 ,
                            },
                            shipping: {
                              currency_code: "EUR",
                              value:  shippingPrice || 0.01 ,
                            },
                            discount: {
                              currency_code: "EUR",
                              value:  fixDecimal(fixDecimal(totalPrice - shippingPrice)*productDiscount/100) || 0 ,
                            },
                            shipping_discount: {
                              currency_code: "EUR",
                              value:  fixDecimal(shippingPrice*shippingDiscount/100) || 0,
                            },
                          },
                        },
                        shipping: {
                          name: {
                            full_name: `${address.uName+" "+address.lName}`,
                          },
                          address: {
                            address_line_1: `${address.street}`,
                            address_line_1: `${address.addressline2}`,
                            admin_area_2: `${address.city}`,
                            admin_area_1: `${address.state}`,
                            postal_code: `${address.zip}`,
                            country_code: `${address.countryCode}`,
                          },
                        },
                        items: sepet.map((item) => ({
                          name: item.title[2],
                          unit_amount: {
                            currency_code: "EUR",
                            value:  item.price || 0.01 ,
                          },
                          quantity: item.adet,
                          description: " ",
                        })),
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    saveOrderToDb(data.orderID, details);
                  });
                }}
              />
            </PayPalScriptProvider>
          )}
        </Paper>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Payment2;
