import React, { createContext, useContext, useEffect, useState } from "react";
const LanguageContext = createContext();

const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("tr");
  const [languageIndex, setLanguageIndex] = useState(0);
  console.log("language=>",language)
  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, languageIndex, setLanguageIndex }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export default LanguageContextProvider;
