import React, { createContext, useContext, useEffect, useRef, useState } from "react";
const SepetContext = createContext();

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SepetContextProvider = ({ children }) => {
  const [sepet, setSepet] = useState([]);
  const [productDiscount, setProductDiscount] = useState(0)
  const prev = usePrevious(sepet);
  useEffect(() => {
    if (sepet.length > 0) localStorage.setItem("sepet", JSON.stringify(sepet));
    if(prev && sepet.length == 0 && prev.length > 0) localStorage.setItem("sepet", JSON.stringify(sepet));
  }, [sepet]);
  console.log("urun sepete eklendi:", sepet);

  useEffect(() => {
    const sepettekiler = JSON.parse(localStorage.getItem("sepet"));
    if (sepettekiler) {
      setSepet(sepettekiler);
    }
  }, []);

  const deleteFromSepet = (id) => {
    setSepet((currentSepet) => currentSepet.filter((item) => item.id != id));
  };
  const deleteAllProducts = () => {
    setSepet([]);
    setProductDiscount(0);
    localStorage.removeItem("sepet");
  };

  return (
    <SepetContext.Provider value={{ sepet, setSepet, deleteFromSepet, deleteAllProducts, productDiscount, setProductDiscount }}>
      {children}
    </SepetContext.Provider>
  );
};

export const useSepet = () => useContext(SepetContext);

export default SepetContextProvider;
