import { Card, styled } from "@mui/material";

export const CardAppl = styled(Card)`
  border: 0px;
  background-color: #fff;
  border-radius: 18px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  max-width: 600px;
  width: calc(90% - 40px);
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  :hover {
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
    transform: scale3d(1.01, 1.01, 1.01);
  }
  @media only screen and (max-width: 600px) {
    padding: 10px;
    width: calc(95% - 20px);
  }
`;
