import React, { createContext, useState, useEffect, useContext } from "react";

import firebase from "../utils/firebase";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.adminSigningIn = false;
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((userx) => {
        if (userx) {
          setUser(userx);
        } else {
          if (!window.adminSigningIn) {
            firebase
              .auth()
              .signInAnonymously()
              .then(() => {
                // Signed in..
              })
              .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
              });
          }
        }
      });
    return () => unregisterAuthObserver();
  }, []);
  useEffect(() => {
    console.log("user=>",user);
  }, [user]);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuth = () => useContext(AuthContext);
