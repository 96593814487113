import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import DirectionsIcon from "@mui/icons-material/Directions";
import PagesWrapper from "../../../components/PagesWrapper";
import { useOrders } from "../../../contexts/Orders";
import { v4 as uuidv4 } from "uuid";

import firebase from "../../../utils/firebase";

var formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const message = (orderId, shippingCompany, shippingNumber, status) => {
  let directmessage = [
    `Siparişiniz kargoya verildi.`,
    `Ihre Bestellung wurde versandt.`,
    `Your order has been shipped.`,
  ];
  let updatemessage = [
    `Kargo numaranız değiştirildi. | shipping company: ${shippingCompany} | shipping number: ${shippingNumber}`,
    `Ihre Frachtnummer wurde geändert. | shipping company: ${shippingCompany} | shipping number: ${shippingNumber}`,
    `Your cargo number has been changed. | shipping company: ${shippingCompany} | shipping number: ${shippingNumber}`,
  ];
  let newmessage = [
    `Siparişiniz kargoya verildi. Kargonuzu kargo şirketinden kargo numarası ile sorgulayabilirsiniz. | shipping company: ${shippingCompany} | shipping number: ${shippingNumber}`,
    `Ihre Bestellung wurde versandt. Sie können Ihre Fracht mit der Frachtnummer bei der Frachtgesellschaft abfragen. | shipping company: ${shippingCompany} | shipping number: ${shippingNumber}`,
    `Your order has been shipped. You can inquire your cargo with the cargo number from the cargo company. | shipping company: ${shippingCompany} | shipping number: ${shippingNumber}`,
  ];
  let updatetitle = [
    "Gönderi numarası değişti | " + orderId,
    "Versandnummer geändert | " + orderId,
    "Shipping number changed | " + orderId,
  ];
  let newtitle = [
    "Siparişiniz kargoya verildi | " + orderId,
    "Ihre Bestellung wurde versandt | " + orderId,
    "Your order has been shipped | " + orderId,
  ];
  let directtitle = [
    "Siparişiniz kargoya verildi | " + orderId,
    "Ihre Bestellung wurde versandt | " + orderId,
    "Your order has been shipped | " + orderId,
  ];
  console.log("xx=>", shippingNumber ? "ab" : "cb");
  return {
    title:
      status == "neworder"
        ? shippingNumber
          ? newtitle
          : directtitle
        : shippingNumber
        ? updatetitle
        : directtitle,
    message:
      status == "neworder"
        ? shippingNumber
          ? newmessage
          : directmessage
        : shippingNumber
        ? updatemessage
        : directmessage,
    delivered: false,
    messageId: uuidv4(),
  };
};

const AdminOrder = () => {
  const { orders } = useOrders();
  const [shippingNumber, setShippingNumber] = useState("");
  const [sepet, setSepet] = useState();
  const [shippingPrice, setShippingPrice] = useState(0);
  const [shippingCompany, setShippingCompany] = useState();

  const [productDiscount, setProductDiscount] = useState(0);
  const [shippingDiscount, setShippingDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const shippingPriceIndirim =
    ((shippingPrice ? shippingPrice : 0) * shippingDiscount) / 100;
  const toplamProductFiyatIndirim =
    (((totalPrice ? totalPrice : 0) - (shippingPrice ? shippingPrice : 0)) *
      productDiscount) /
    100;
  const [address, setAddress] = useState();
  const [weight, setWeight] = useState();
  const [status, setStatus] = useState();
  const [orderUid, setOrderUid] = useState();
  const location = useLocation();
  const path = location.pathname.split("/");
  const orderId = path[path.length - 1];
  useEffect(() => {
    if (orders.filter((item) => item.orderId == orderId).length > 0) {
      const order = orders.filter((item) => item.orderId == orderId)[0];
      setSepet(order.sepet);
      setShippingPrice(order.shippingPrice);
      setTotalPrice(order.totalPrice);
      setShippingDiscount(order.shippingDiscount ? order.shippingDiscount : 0);
      setProductDiscount(order.productDiscount ? order.productDiscount : 0);
      setAddress(order.address);
      setWeight(order.weight);
      setShippingCompany(order.address.shippingCompany);
      setStatus(order.status);
      console.log(order);
      setShippingNumber(order.shippingNumber);
      setOrderUid(order.uid);
    }
  }, [orders]);
  if (orders.filter((item) => item.orderId == orderId).length == 0) {
    return <PagesWrapper>Order not found</PagesWrapper>;
  }

  const languageIndex = 2;
  const handleShippingNumberChange = async (e) => {
    e.preventDefault();
    await firebase
      .firestore()
      .collection("orders")
      .doc(orderId)
      .update({ shippingNumber: shippingNumber || "", status: "shipped" });
    let m = message(orderId, shippingCompany, shippingNumber, status);
    await firebase
      .firestore()
      .collection("users")
      .doc(orderUid)
      .collection("appMessages")
      .doc(m.messageId)
      .set(m);
  };
  return (
    <PagesWrapper>
      <Container maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          {status == "shipped" ? (
            <Typography sx={{ mb: 3 }}>Edit shipping number:</Typography>
          ) : (
            <Typography sx={{ mb: 3 }}>
              Enter shipping number, we will send app-message to client:
            </Typography>
          )}
          <Paper
            component="form"
            onSubmit={handleShippingNumberChange}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter shipping number"
              value={shippingNumber}
              onChange={(e) => setShippingNumber(e.target.value)}
              inputProps={{
                "aria-label": "shipping number",
              }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              type="submit"
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            sx={{ my: 3 }}
            onClick={handleShippingNumberChange}
          >
            gonderi numarasi olmadan kargo.
          </Button>
          <List disablePadding>
            {sepet &&
              sepet.map((product) => (
                <ListItem
                  key={product.title[languageIndex]}
                  sx={{ py: 1, px: 0 }}
                >
                  <ListItemText
                    primary={product.title[languageIndex]}
                    secondary={
                      product.adet +
                      [" Adet", " Stück", " Pieces"][languageIndex]
                    }
                  />
                  <Typography variant="body2">
                    {formatter.format(product.price)}
                  </Typography>
                </ListItem>
              ))}
            <ListItem sx={{ py: 1, px: 0 }}>
              <ListItemText primary={"Shipping"} secondary={weight + " kg"} />
              <Typography variant="body2">
                {shippingPriceIndirim > 0 ? (
                  <>
                    <del>{formatter.format(shippingPrice)}</del>{" "}
                    {formatter.format(shippingPrice - shippingPriceIndirim)}
                  </>
                ) : (
                  formatter.format(shippingPrice)
                )}
              </Typography>
            </ListItem>

            <ListItem sx={{ py: 1, px: 0 }}>
              <ListItemText primary="Total" />
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                {shippingDiscount || productDiscount ? (
                  <>
                    <del>{formatter.format(totalPrice)}</del>{" "}
                    {formatter.format(
                      totalPrice -
                        toplamProductFiyatIndirim -
                        shippingPriceIndirim
                    )}
                  </>
                ) : (
                  formatter.format(totalPrice)
                )}
              </Typography>
            </ListItem>
          </List>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Shipping
              </Typography>
              <Typography gutterBottom>
                {address && address.uName + " " + address.lName}
              </Typography>

              {address && (
                <>
                  <Typography gutterBottom variant="body2">
                    Street: {address.street}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    House Number:{" "}
                    {address.addressline2 ? address.addressline2 + "" : ""}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Zip,City,Country:{" "}
                    {address.zip + " " + address.city + " " + address.country}
                  </Typography>
                </>
              )}

              <Typography gutterBottom>
                {shippingCompany && shippingCompany}
              </Typography>
              <Typography gutterBottom>{address && address.phone}</Typography>
              <Typography gutterBottom>{address && address.email}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </PagesWrapper>
  );
};

export default AdminOrder;
