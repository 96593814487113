import React from "react";

import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import DiscountIcon from '@mui/icons-material/Discount';
import EmailIcon from '@mui/icons-material/Email';

import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useOrders } from "../contexts/Orders";

import LogoutIcon from "@mui/icons-material/Logout";
import firebase from "../utils/firebase"
import { useAdminMessages } from "../contexts/AdminMessages";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const HeaderAdmin = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const {newMessages} = useAdminMessages();
  const { newOrders } = useOrders();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Admin
            </Typography>
            <IconButton color="inherit" onClick={()=>{firebase.auth().signOut();navigate("/admin/signin")}}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" sx={{ minHeight: "80vh" }}>
            <ListItemButton onClick={() => navigate("/admin")}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/admin/neworders")}>
              <ListItemIcon>
                <Badge badgeContent={newOrders.length} color="secondary">
                  <FiberNewIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="New orders" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/admin/shippedorders")}>
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Shipped orders" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/admin/coupons")}>
              <ListItemIcon>
                <DiscountIcon />
              </ListItemIcon>
              <ListItemText primary="Coupon codes" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/admin/messages")}>
              <ListItemIcon>
                <Badge badgeContent={newMessages.length} color="secondary">
                  <EmailIcon/>
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Messages" />
            </ListItemButton>

            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
              Saved reports
            </ListSubheader>
            <ListItemButton>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Daily user count" />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <EventBusyIcon />
              </ListItemIcon>
              <ListItemText primary="Canceled orders" />
            </ListItemButton>
          </List>
        </Drawer>
      </Box>
    </>
  );
};

export default HeaderAdmin;
