import { useLanguage } from "../../contexts/Language"
import { Container } from "@mui/material"



const TermsOfUse = () => {
    const { language } = useLanguage()
    return (
        <Container>
            {
                language == "tr" && (
                    `Genel Hüküm ve Koşullar Berlin Cigkofte, Genel Müdür Abdulbaki Özcan
                    Herrfurthstraße 32, 12049 Berlin, Almanya
                    AGB cigkofteshop.com
                    § 1 Uygulama kapsami
                    (1) Bu Genel Hüküm ve Koşullar, www.cigkofteshop.com,  Berlin Cigkofte ve müşterileri arasindaki tüm iş ilişkileri için sözleşmenin imzalandiği tarihte geçerli olan ilgili versiyonda geçerlidir.
                    (2) Bu Genel Hüküm ve Koşullar münhasiran geçerlidir. Müşterinin bu genel hüküm ve koşullardan sapan çelişkili koşullari veya koşullari, cigkofteshop.com bunlari münferit durumlarda yazili olarak açikça kabul etmedikçe taninmayacaktir.
                    (3) Aşağidaki düzenlemelerin her biri için, müşterinin BGB §13 uyarinca bir tüketici veya BGB §14 paragraf 1 uyarinca bir girişimci olup olmadiği konusunda bir ayrim yapilir. Tüketiciler ve girişimciler için farkli olan hükümler söz konusu olduğunda, metinde tüketiciler (§13 BGB) ve girişimciler (§14 paragraf 1 BGB) arasinda bir ayrim yapilmaktadir. Metinde yalnizca “müşteri “den bahsediliyorsa, yönetmelik hem tüketiciler hem de girişimciler için geçerlidir.
                    § 2 Kayit
                    (1) Her mal siparişi, cigkofteshop.comda müşteri olarak önceden kayit yaptirmayi gerektirir. Farkli isimler veya adresler altinda birden fazla kayit kabul edilemez. Kayit işleminin başariyla tamamlanmasiyla müşteri cigkofteshop.com tarafindan çevrimiçi mağazaya kabul edilir.
                    (2) Kabul, cigkofteshop.com tarafindan herhangi bir zamanda ve sebep gösterilmeksizin iptal edilebilir. Bu durumda cigkofteshop.com, kullanici adini ve ilgili şifreyi derhal bloke etme hakkina sahiptir.
                    § 3 Sözleşmenin imzalanmasi
                    (1) Online mağazadaki ürünlerin sunumu yasal olarak bağlayici bir teklifi değil, bağlayici olmayan bir online kataloğu temsil eder. Müşteri, cigkofteshop.comun çevrimiçi mağazasinda bir sipariş işlemini tamamlayarak veya cigkofteshop.coma e-posta ile bir sipariş göndererek, §§ 145 ff. anlaminda bir teklifte bulunur. cigkofteshop.com ile bir satin alma sözleşmesi imzalandiğinda BGB. Müşteri, siparişin alindiğina dair onayi e-posta yoluyla alacaktir (sipariş onayi). Bu sipariş onayi, teklifin kabul edildiğini açikça göstermez, yalnizca siparişin cigkofteshop.com tarafindan alindiğini müşteriye bildirmeyi amaçlar.
                    (2) cigkofteshop.com ile sözleşme ancak cigkofteshop.comun sipariş edilen ürünü müşteriye göndererek bu teklifi kabul etmesi halinde akdedilecektir. Sevkiyat onayinda listelenmeyen tek ve ayni siparişe ait ürünler için satin alma sözleşmesi yapilmayacaktir.
                    (3) Sözleşme metni, sözleşmenin imzalanmasindan sonra cigkofteshop.comda saklanir ve müşteri tarafindan talep edilebilir. Sipariş verileri, gönderildikten hemen sonra müşteri tarafindan yazdirilabilir veya e-posta sipariş onayini yazdirabilir.
                    (4) Sözleşme dili olarak sadece Türkçe mevcuttur.
                    § 4 Teslimat, nakliye masraflari
                    (1) cigkofteshop.com, ürün açiklamasinda farkli bir teslimat süresi belirtilmediği sürece, sipariş edilen ürünleri siparişten sonra Almanya içinde yaklaşik 7-10 iş günü, AB ülkeleri içinde en fazla 14 gün ve dünya çapinda en fazla 28 gün içinde müşteri tarafindan siparişte belirtilen adrese teslim edecektir. cigkofteshop.com, müşteri için makul olmasi koşuluyla, herhangi bir zamanda kismi teslimat ve kismi hizmet verme hakkina sahiptir. Kismi teslimatlarin cigkofteshop.com tarafindan yapilmasi durumunda, cigkofteshop.com ek posta masraflarini karşilayacaktir. (2) Mallar, sipariş süreci sirasinda müşteriye gösterilecek olan çeşitli nakliye ortaklari araciliğiyla gönderilecektir. Seçilen nakliye ortağindan bağimsiz olarak, cigkofteshop.com, müşteri tüketici olduğu sürece nakliye riskini üstlenecektir.
                    (3) cigkofteshop.com kendi kusuru olmaksizin sipariş edilen mallari teslim edemezse, cigkofteshop.com müşteri ile yaptiği sözleşmeden cayma hakkina sahip olacaktir. Böyle bir durumda cigkofteshop.com müşteriyi sipariş edilen mallarin mevcut olmadiği konusunda derhal bilgilendirecektir. Müşteri tarafindan halihazirda ödenmiş olan her türlü bedel derhal iade edilecektir.
                    (4) Savaş, doğal afetler, grev ve mücbir sebepler gibi önceden öngörülemeyen olaylarin meydana gelmesi nedeniyle cigkofteshop.comun teslim yükümlülüğünü yerine getirememesi ve durumun gereklerine göre makul özeni göstermesine rağmen bunlari önleyememesi halinde, teslim süresi buna uygun olarak uzayacaktir. cigkofteshop.com ayrica müşteriyi bu konuda gecikmeksizin bilgilendirecektir. Müşterinin yasal talepleri bundan etkilenmeyecektir.
                    § 5 Unvanin sakli tutulmasi
        
                    Mallar, ödemenin tamami yapilana kadar cigkofteshop.comun mülkiyetinde kalir. Mülkiyetin devri, rehin verilmesi, teminat yoluyla devredilmesi, işlenmesi veya dönüştürülmesine iznimiz olmadan izin verilmez.
        
                    § 6 Vade tarihi ve ödeme
        
                    (1) cigkofteshop.com sadece sipariş sürecinde müşteriye gösterilen ödeme yöntemlerini kabul eder.
        
                    (2) Satin alma fiyati ve varsa nakliye masraflari sözleşmenin imzalanmasi üzerine ödenir.
        
                    § 7 Mallarin satişi
        
                    cigkofteshop.com müşterisi olarak cigkofteshop.comdan satin aldiğiniz kitaplari cigkofteshop.com müşterilerine kullanilmiş kitap olarak cigkofteshop.com üzerinden satabilirsiniz. Bu satiş sadece ilgili kitaplarin satin alinan miktari kadar gerçekleşebilir. Müşteri satici bu satiştan kendisi sorumludur ve satin alma işleminin nakliye ve taşima işlemlerinden sorumludur. cigkofteshop.com bu satiş için komisyon alma hakkini sakli tutar. Bu komisyon her zaman talep edilebilir.
        
                    § 8 İptal
                    Müşteri bir tüketici ise, sözleşmeyi aşağidaki şekilde iptal edebilir:
                    İptal hakki
                    Bu sözleşmeyi on dört gün içinde herhangi bir sebep göstermeksizin iptal etme hakkina sahipsiniz. İptal süresi, sizin veya sizin tarafinizdan belirlenen ve taşiyici olmayan üçüncü bir tarafin son mallari teslim aldiği veya teslim aldiği günden itibaren on dört gündür.
                    Cayma hakkinizi kullanmak için bizimle şu adresten iletişime geçmelisiniz
                    cigkofteshop.com
                    Abdulbaki Özcan
                    Herrfurthstraße 32, 12049 Berlin, Almanya
        
        
        
        
                    Bu sözleşmeyi iptal etme kararinizi açik bir beyanla (örneğin posta yoluyla gönderilen bir mektup veya e-posta).
        
                    İptal süresine uymak için, iptal süresinin sona ermesinden önce iptal hakkinin kullanildiğina dair bildirimi göndermeniz yeterlidir.
        
                    İptalin sonuçlari
        
                    Bu sözleşmeden çekilmeniz halinde, teslimat masraflari da dahil olmak üzere sizden aldiğimiz tüm ödemeleri (tarafimizca sunulan en ucuz standart teslimat dişinda bir teslimat türü seçmiş olmanizdan kaynaklanan ek masraflar hariç) gecikmeksizin ve en geç bu sözleşmeden çekildiğinize dair bildirimi aldiğimiz günden itibaren on dört gün içinde iade etmeliyiz. Bu geri ödeme için, sizinle açikça aksi kararlaştirilmadikça, orijinal işlem için kullandiğiniz ayni ödeme yöntemini kullanacağiz; hiçbir durumda bu geri ödeme için sizden ücret alinmayacaktir. Mallari geri alana kadar veya siz mallari iade ettiğinize dair kanit sunana kadar (hangisi daha önceyse) geri ödemeyi reddedebiliriz.
        
                    Mallari gecikmeden ve her halükarda bu sözleşmenin iptalini bize bildirdiğiniz günden itibaren en geç on dört gün içinde bize iade etmeli veya teslim etmelisiniz. Mallari on dört günlük sürenin bitiminden önce gönderirseniz son teslim tarihi yerine getirilmiş olur. Mallarin iadesi ile ilgili doğrudan masraflar tarafinizdan karşilanacaktir.
        
                    Mallarin değerindeki herhangi bir kaybi, yalnizca bu değer kaybi mallarin durumunun, özelliklerinin ve işleyişinin kontrol edilmesi için gerekli olmayan elleçlemeden kaynaklaniyorsa ödemek zorundasiniz.
        
                    Cayma hakkinin hariç tutulmasi
        
                    Mesafeli sözleşmeler için iptal hakki mevcut değildir
        
                    - ses veya video kayitlarinin veya bilgisayar yazilimlarinin mühürlü ambalajlarda teslim edilmesi durumunda, mühür teslimattan sonra sökülmüşse;
                    - sağliğin korunmasi veya hijyen nedeniyle iadeye uygun olmayan mühürlü mallarin teslimati durumunda, teslimattan sonra mühürleri sökülmüşse.
        
                    İptal politikasinin sonu
        
                    § 9 Bozma işlemine ilişkin notlar
        
                    cigkofteshop.com, iade işleminin duyurulmasi için müşterinin mallari iade etmeden önce 0221- 25070090 numarali telefonu aramasini ister. Bu şekilde, ürünlerin mümkün olan en hizli şekilde tahsis edilmesini ve siparişin hizli bir şekilde geri alinmasini sağlar. Bu talebe uyulmasi, cayma hakkinin etkili bir şekilde kullanilmasi için bir ön koşul değildir.
        
                    § 10 Kusurlar için sorumluluk
        
                    cigkofteshop.com, tüketicinin yazili bildirimi ile yasal gerekliliklere göre on dört günlük garanti süresi içinde kitaplarin tüketiciye tesliminde mevcut olan ayiplar için varsa kitaplarin değişimini yapacak veya para iadesinde bulunacaktir.
        
        
                    §11 Adresler
        
                    İş ve iade teslimat adresi:
        
                    cigkofteshop.com
                    Abdulbaki Özcan
                    Herrfurthstraße 32, 12049 Berlin, Almanya
        
                    § 12 Sorumluluk
        
                    (1) cigkofteshop.com, kasitli olarak veya ağir ihmal sonucu meydana gelen zararlardan, kusurlarin hileli olarak gizlenmesi durumunda, kalite garantisinin üstlenilmesi durumunda ve bedensel yaralanmalardan sinirlama olmaksizin sorumlu olacaktir. Ürün Sorumluluğu Yasasi hükümleri bundan etkilenmeyecektir.
        
                    (2) Basit ihmalden kaynaklanan diğer zararlar için cigkofteshop.com yalnizca bir yükümlülüğün ihlal edilmesi, bu yükümlülüğe uyulmasinin sözleşmenin amacina ulaşmasi için özel bir öneme sahip olmasi (temel yükümlülük) ve zararlarin mallarin sözleşmeye bağli kullanimi nedeniyle tipik ve öngörülebilir olmasi durumunda sorumlu olacaktir. Özellikle teslimat kaleminin kendisinde meydana gelmeyen hasarlar, kar kaybi veya diğer mali kayiplar için başka herhangi bir sorumluluk kabul edilmez. cigkofteshop.comun sorumluluğu hariç tutulduğu veya sinirlandirildiği ölçüde, bu ayni zamanda çalişanlarin, temsilcilerin ve vekillerin kişisel sorumluluğu için de geçerlidir.
        
                    § 13 Nihai hükümler
        
                    (1) Taraflarin hukuki ilişkilerinde, uluslararasi özel hukuk normlari referans alinmaksizin ve BM Uluslararasi Mal Satimina İlişkin Sözleşmeler Antlaşmasi hariç olmak üzere Alman hukuku uygulanacaktir.
        
                    (2) Müşteri bir tacir, kamu hukuku kapsaminda bir tüzel kişilik veya kamu hukuku kapsaminda özel bir fon ise, bu sözleşmeden kaynaklanan tüm anlaşmazliklar için yargi yeri kayitli ofisimiz olacaktir.
        
                    § 14 Çevrimiçi Uyuşmazlik Çözümü
        
                    Avrupa Komisyonu, çevrimiçi uyuşmazlik çözümü için aşağidaki platformu sağlamaktadir (OS olarak adlandirilir):
        
                    www.ec.europa.eu/consumers/odr`
                )
            }
            {
                language == "de" && (
                    `Allgemeine Geschäftsbedingungen Berlin Cigkofte, General Manager Abdulbaki Özcan
                    Herrfurthstraße 32, 12049 Berlin, Deutschland
                    AGB zigkofteshop.de
                    § 1 Anwendungsbereich
                    (1) Diese Allgemeinen Geschäftsbedingungen gelten für alle Geschäftsbeziehungen zwischen www.cigkofteshop.com, Berlin Cigkofte und ihren Kunden in der jeweiligen, zum Zeitpunkt des Vertragsschlusses gültigen Fassung.
                    (2) Diese Allgemeinen Geschäftsbedingungen gelten ausschließlich. Entgegenstehende oder von diesen Allgemeinen Geschäftsbedingungen abweichende Bedingungen des Kunden werden nicht anerkannt, es sei denn, cigkofteshop.com stimmt ihnen im Einzelfall ausdrücklich schriftlich zu.
                    (3) Bei den folgenden Regelungen wird jeweils danach unterschieden, ob der Kunde Verbraucher im Sinne des § 13 BGB oder Unternehmer im Sinne des § 14 Abs. 1 BGB ist. Bei den unterschiedlichen Regelungen für Verbraucher und Unternehmer wird im Text zwischen Verbrauchern (§13 BGB) und Unternehmern (§14 Abs. 1 BGB) unterschieden. Wenn im Text nur von "Kunden" die Rede ist, gilt die Regelung sowohl für Verbraucher als auch für Unternehmer.
                    § 2 Registrierung
                    (1) Jede Bestellung von Waren erfordert eine vorherige Registrierung als Kunde bei cigkofteshop.com. Mehrfachanmeldungen unter verschiedenen Namen oder Adressen können nicht akzeptiert werden. Nach erfolgreichem Abschluss des Registrierungsprozesses wird der Kunde von cigkofteshop.com in den Online-Shop aufgenommen.
                    (2) Die Annahme kann von cigkofteshop.com jederzeit und ohne Angabe von Gründen widerrufen werden. In diesem Fall ist cigkofteshop.com berechtigt, den Benutzernamen und das dazugehörige Passwort sofort zu sperren.
                    § 3 Zustandekommen des Vertrages
                    (1) Die Darstellung der Produkte im Online-Shop stellt kein rechtlich bindendes Angebot, sondern einen unverbindlichen Online-Katalog dar. Durch den Abschluss eines Bestellvorgangs im Online-Shop von cigkofteshop.com oder durch das Absenden einer Bestellung per E-Mail an cigkofteshop.com gibt der Kunde ein Angebot im Sinne der §§ 145 ff. BGB auf Abschluss eines Kaufvertrages mit cigkofteshop.com ab. Diese Bestellbestätigung stellt keine ausdrückliche Annahme des Angebots dar, sondern soll den Kunden nur darüber informieren, dass die Bestellung bei cigkofteshop.com eingegangen ist.
                    (2) Der Vertrag mit cigkofteshop.com kommt erst zustande, wenn cigkofteshop.com dieses Angebot durch Zusendung der bestellten Ware an den Kunden angenommen hat. Über einzelne Produkte der gleichen Bestellung, die nicht in der Versandbestätigung aufgeführt sind, kommt kein Kaufvertrag zustande.
                    (3) Der Vertragstext wird nach Vertragsschluss bei cigkofteshop.com gespeichert und kann vom Kunden abgerufen werden. Die Bestelldaten können vom Kunden unmittelbar nach dem Versand eingegeben werden oder die E-Mail-Bestellbestätigung kann vom Kunden eingegeben werden.
                    (4) Als Vertragssprache ist nur Türkisch verfügbar.
                    § 4 Lieferung, Transportkosten
                    (1) cigkofteshop.com liefert die bestellte Ware an die vom Kunden in der Bestellung angegebene Adresse innerhalb von ca. 7-10 Werktagen innerhalb Deutschlands, maximal 14 Tagen innerhalb der EU-Länder und maximal 28 Tagen weltweit nach der Bestellung, es sei denn, in der Produktbeschreibung ist eine andere Lieferzeit angegeben. cigkofteshop.com ist zu Teillieferungen und Teilleistungen jederzeit berechtigt, sofern dies für den Kunden zumutbar ist. Bei Teillieferungen durch cigkofteshop.com trägt cigkofteshop.com die zusätzlichen Portokosten. (2) Der Versand der Ware erfolgt über verschiedene Transportpartner, die dem Kunden während des Bestellvorgangs angezeigt werden. Unabhängig vom gewählten Transportpartner trägt cigkofteshop.com das Transportrisiko, solange der Kunde ein Verbraucher ist.
                    (3) Falls cigkofteshop.com ohne eigenes Verschulden zur Lieferung der bestellten Ware nicht in der Lage ist, ist cigkofteshop.com zum Rücktritt vom Vertrag mit dem Kunden berechtigt. In einem solchen Fall wird cigkofteshop.com den Kunden unverzüglich darüber informieren, dass die bestellte Ware nicht verfügbar ist. Der vom Kunden bereits gezahlte Preis wird unverzüglich zurückerstattet.
                    (4) Für den Fall, dass cigkofteshop.com durch den Eintritt unvorhergesehener Ereignisse wie Krieg, Naturkatastrophen, Streiks und höhere Gewalt an der Erfüllung ihrer Lieferverpflichtung gehindert wird und diese trotz der nach den Umständen des Falles zumutbaren Sorgfalt nicht abwenden kann, verlängert sich die Lieferfrist entsprechend. cigkofteshop.com wird den Kunden auch hierüber unverzüglich informieren. Gesetzliche Ansprüche des Kunden bleiben hiervon unberührt.
                    § 5 Eigentumsvorbehalt
                    
                    Die Ware bleibt bis zur vollständigen Bezahlung Eigentum von cigkofteshop.com. Die Abtretung, Verpfändung, Sicherungsübereignung, Verarbeitung oder Umgestaltung des Eigentums ist ohne unsere Zustimmung nicht gestattet.
        
                    § 6 Fälligkeit und Zahlung
        
                    (1) cigkofteshop.com akzeptiert nur die Zahlungsarten, die dem Kunden während des Bestellvorgangs angezeigt werden.
        
                    (2) Der Kaufpreis und ggf. die Transportkosten sind bei Vertragsschluss fällig.
        
                    § 7 Verkauf von Waren
        
                    Als Kunde von cigkofteshop.com können Sie Bücher, die Sie bei cigkofteshop.com gekauft haben, als gebrauchte Bücher über cigkofteshop.com an Kunden von cigkofteshop.com verkaufen. Dieser Verkauf kann nur bis zu der gekauften Menge der betreffenden Bücher realisiert werden. Der Kunde Verkäufer ist verantwortlich für diesen Verkauf und ist verantwortlich für den Versand und die Abwicklung des Kaufs. cigkofteshop.com behält sich das Recht vor, eine Provision für diesen Verkauf zu berechnen. Diese Provision kann jederzeit beantragt werden.
        
                    § 8 Löschung
                    Wenn der Kunde ein Verbraucher ist, kann er den Vertrag wie folgt widerrufen:
                    Recht auf Widerruf
                    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.
                    Um Ihr Widerrufsrecht auszuüben, müssen Sie sich mit uns in Verbindung setzen
                    zigkofteshop.de
                    Abdulbaki Özcan
                    Herrfurthstraße 32, 12049 Berlin, Deutschland
        
        
        
        
                    mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen.
        
                    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
        
                    Folgen der Annullierung
        
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, wir haben mit Ihnen ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
        
                    Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Die unmittelbaren Kosten der Rücksendung der Waren gehen zu Ihren Lasten.
        
                    Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
        
                    Ausschluss des Widerrufsrechts
        
                    Kein Widerrufsrecht bei Fernabsatzverträgen
        
                    - bei Lieferung von Audio- oder Videoaufzeichnungen oder Computersoftware in einer versiegelten Verpackung, sofern die Versiegelung nach der Lieferung entfernt wurde;
                    - bei der Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde.
        
                    Ende der Widerrufsbelehrung
        
                    § 9 Hinweise zum Rückabwicklungsverfahren
        
                    cigkofteshop.com bittet den Kunden, vor der Rücksendung der Ware die Nummer 0221- 25070090 anzurufen, um den Rückgabevorgang anzukündigen. Auf diese Weise wird eine schnellstmögliche Bereitstellung der Produkte und ein schneller Abruf der Bestellung gewährleistet. Die Befolgung dieser Aufforderung ist keine Voraussetzung für die wirksame Ausübung des Widerrufsrechts.
        
                    § 10 Haftung für Mängel
        
                    cigkofteshop.com ersetzt oder erstattet die Bücher, falls vorhanden, bei Mängeln, die zum Zeitpunkt der Lieferung der Bücher an den Verbraucher vorhanden sind, innerhalb der vierzehntägigen Garantiezeit gemäß den gesetzlichen Bestimmungen mit der schriftlichen Benachrichtigung des Verbrauchers.
        
                    §11 Adressen
        
                    Geschäfts- und Rücklieferadresse:
        
                    zigkofteshop.de
                    Abdulbaki Özcan
                    Herrfurthstraße 32, 12049 Berlin, Deutschland
        
                    § 12 Haftung
        
                    (1) cigkofteshop.com haftet unbeschränkt für vorsätzliche oder grob fahrlässige Schäden, arglistiges Verschweigen von Mängeln, Übernahme einer Beschaffenheitsgarantie und Körperschäden. Die Bestimmungen des Produkthaftungsgesetzes bleiben unberührt.
        
                    (2) Für sonstige Schäden, die durch einfache Fahrlässigkeit verursacht werden, haftet cigkofteshop.com nur, sofern eine Pflicht verletzt wird, deren Einhaltung für die Erreichung des Vertragszwecks von besonderer Bedeutung ist (Kardinalpflicht) und die Schäden aufgrund der vertraglichen Verwendung der Ware typisch und vorhersehbar sind. Eine weitergehende Haftung für Schäden, entgangenen Gewinn oder sonstige Vermögensschäden, die nicht am Liefergegenstand selbst entstanden sind, wird nicht übernommen. Soweit die Haftung von cigkofteshop.com ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.
        
                    § 13 Schlussbestimmungen
        
                    (1) Für die Rechtsbeziehungen der Parteien gilt deutsches Recht ohne Verweis auf die Normen des Internationalen Privatrechts und mit Ausnahme des UN-Kaufrechts.
        
                    (2) Ist der Kunde Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, so ist Gerichtsstand für alle Streitigkeiten aus diesem Vertrag unser Geschäftssitz.
        
                    § 14 Online-Streitbeilegung
        
                    Die Europäische Kommission stellt die folgende Plattform für die Online-Streitbeilegung (OS) zur Verfügung
        
                    www.ec.europa.eu/consumers/odr
                    `
                )
            }
            {
                language == "en" && (
                    `General Terms and Conditions Berlin Cigkofte, General Manager Abdulbaki Özcan
                    Herrfurthstrasse 32, 12049 Berlin, Germany
                    AGB cigkofteshop.com
                    § 1 Scope of application
                    (1) These General Terms and Conditions apply to all business relationships between www.cigkofteshop.com, Berlin Cigkofte and its customers in the respective version in effect at the time of conclusion of the contract.
                    (2) These General Terms and Conditions apply exclusively. Conflicting terms or conditions of the customer that deviate from these general terms and conditions shall not be recognized unless cigkofteshop.com expressly accepts them in writing in individual cases.
                    (3) For each of the following regulations, a distinction is made as to whether the customer is a consumer pursuant to §13 BGB or an entrepreneur pursuant to §14 para. 1 BGB. In the case of the different provisions for consumers and entrepreneurs, the text makes a distinction between consumers (§13 BGB) and entrepreneurs (§14 para. 1 BGB). If the text only mentions "customer", the regulation applies to both consumers and entrepreneurs.
                    § 2 Registration
                    (1) Every order of goods requires prior registration as a customer on cigkofteshop.com. Multiple registrations under different names or addresses cannot be accepted. Upon successful completion of the registration process, the customer is accepted by cigkofteshop.com into the online shop.
                    (2) Acceptance may be canceled by cigkofteshop.com at any time and without giving reasons. In this case, cigkofteshop.com is entitled to block the user name and the corresponding password immediately.
                    § 3 Conclusion of the contract
                    (1) The presentation of products in the online shop does not represent a legally binding offer, but a non-binding online catalog. By completing an order transaction in the online shop of cigkofteshop.com or by sending an order to cigkofteshop.com by e-mail, the customer makes an offer within the meaning of §§ 145 ff. BGB when a purchase contract is concluded with cigkofteshop.com. The customer will receive confirmation of receipt of the order by e-mail (order confirmation). This order confirmation does not expressly indicate acceptance of the offer, but is only intended to inform the customer that the order has been received by cigkofteshop.com.
                    (2) The contract with cigkofteshop.com will only be concluded if cigkofteshop.com accepts this offer by sending the ordered product to the customer. No purchase contract will be concluded for single products of the same order that are not listed in the shipment confirmation.
                    (3) The text of the contract is stored at cigkofteshop.com after the conclusion of the contract and can be requested by the customer. The order data can be typed or e-mail order confirmation can be typed by the customer immediately after dispatch.
                    (4) Only Turkish is available as a contract language.
                    § 4 Delivery, transportation costs
                    (1) cigkofteshop.com will deliver the ordered goods to the address specified by the customer in the order within approximately 7-10 working days within Germany, a maximum of 14 days within EU countries and a maximum of 28 days worldwide after the order, unless a different delivery time is specified in the product description. cigkofteshop.com is entitled to partial delivery and partial service at any time, provided that this is reasonable for the customer. In the event that partial deliveries are made by cigkofteshop.com, cigkofteshop.com shall bear the additional postage costs. (2) The goods shall be sent via various shipping partners, which shall be shown to the customer during the ordering process. Regardless of the chosen shipping partner, cigkofteshop.com shall bear the shipping risk as long as the customer is the consumer.
                    (3) If cigkofteshop.com is unable to deliver the ordered goods through no fault of its own, cigkofteshop.com shall be entitled to withdraw from the contract with the customer. In such a case, cigkofteshop.com shall immediately inform the customer that the ordered goods are not available. Any price already paid by the customer will be refunded immediately.
                    (4) In the event that cigkofteshop.com is unable to fulfill its delivery obligation due to the occurrence of unforeseen events such as war, natural disasters, strikes and force majeure and cannot prevent them despite exercising reasonable care according to the requirements of the situation, the delivery period will be extended accordingly. cigkofteshop.com will also inform the customer about this without delay. Legal claims of the customer shall remain unaffected.
                    § 5 Reservation of title
                    
                    The goods remain the property of cigkofteshop.com until full payment has been made. No transfer, pledge, assignment by way of security, processing or conversion of ownership is permitted without our consent.
        
                    § 6 Due date and payment
        
                    (1) cigkofteshop.com only accepts the payment methods shown to the customer during the ordering process.
        
                    (2) The purchase price and shipping costs, if any, are payable upon conclusion of the contract.
        
                    § 7 Sale of goods
        
                    As a customer of cigkofteshop.com, you can sell books purchased from cigkofteshop.com to cigkofteshop.com customers as used books on cigkofteshop.com. This sale can only take place up to the purchased quantity of the relevant books. The customer seller is responsible for this sale and is responsible for the shipping and handling of the purchase. cigkofteshop.com reserves the right to charge a commission for this sale. This commission may be requested at any time.
        
                    § 8 Cancellation
                    If the customer is a consumer, he/she may cancel the contract as follows:
                    Right to cancel
                    You have the right to cancel this contract within fourteen days without giving any reason. The cancellation period is fourteen days from the day on which you or a third party designated by you who is not the carrier has received or taken delivery of the last goods.
                    To exercise your right of withdrawal you must contact us at
                    cigkofteshop.com
                    Abdulbaki Ozcan
                    Herrfurthstrasse 32, 12049 Berlin, Germany
        
        
        
        
                    By a clear statement (e.g. a letter sent by post or e-mail) of your decision to cancel this contract.
        
                    To comply with the cancellation period, it is sufficient to send the notice of exercise of the right to cancel before the expiration of the cancellation period.
        
                    Consequences of cancellation
        
                    If you withdraw from this contract, we must refund all payments we have received from you, including delivery costs (excluding any additional costs arising from the fact that you have chosen a type of delivery other than the cheapest standard delivery offered by us), without delay and at the latest within fourteen days from the day on which we receive notice of your withdrawal from this contract. For this refund, we will use the same payment method as you used for the original transaction, unless we have expressly agreed otherwise with you; in no case will you be charged for this refund. We may refuse a refund until we receive the goods back or until you provide proof that you have returned the goods, whichever is the earlier.
        
                    You must return or deliver the goods to us without delay and in any event no later than fourteen days from the day on which you notify us of the cancellation of this contract. The deadline will be met if you send the goods before the end of the fourteen-day period. Any direct costs associated with the return of the Goods will be borne by you.
        
                    You shall only have to pay for any loss in the value of the goods if this loss in value is caused by handling that is not necessary for checking the condition, characteristics and functioning of the goods.
        
                    Exclusion of the right of withdrawal
        
                    No right to cancel for distance contracts
        
                    - in case of delivery of audio or video recordings or computer software in sealed packages, if the seal has been removed after delivery;
                    - in the case of delivery of sealed goods that are not suitable for return for reasons of health protection or hygiene, if the seals have been removed after delivery.
        
                    End of cancellation policy
        
                    § 9 Notes on the reversal procedure
        
                    cigkofteshop.com asks the customer to call 0221- 25070090 before returning the goods in order to announce the return process. In this way, it ensures the fastest possible allocation of the products and a quick retrieval of the order. Compliance with this request is not a prerequisite for the effective exercise of the right of withdrawal.
        
                    § 10 Liability for defects
        
                    cigkofteshop.com will replace or refund the books, if any, for defects present at the time of delivery of the books to the consumer within the fourteen-day warranty period according to the legal requirements with the written notification of the consumer.
                    §11 Addresses
        
                    Business and return delivery address:
        
                    cigkofteshop.com
                    Abdulbaki Ozcan
                    Herrfurthstrasse 32, 12049 Berlin, Germany
        
                    § 12 Liability
        
                    (1) cigkofteshop.com shall be liable without limitation for intentional or grossly negligent damage, fraudulent concealment of defects, assumption of a quality guarantee and bodily injury. The provisions of the Product Liability Act shall remain unaffected.
        
                    (2) For other damages caused by simple negligence, cigkofteshop.com shall only be liable if an obligation has been breached, compliance with this obligation is of particular importance for the achievement of the contractual purpose (essential obligation) and the damages are typical and foreseeable due to the contractual use of the goods. No further liability shall be assumed for damages, loss of profits or other financial losses that do not occur specifically in the delivery item itself. Insofar as the liability of cigkofteshop.com is excluded or limited, this also applies to the personal liability of employees, representatives and agents.
        
                    § 13 Final provisions
        
                    (1) German law shall apply to the legal relations of the parties, without reference to private international law norms and with the exception of the UN Convention on Contracts for the International Sale of Goods.
        
                    (2) If the Customer is a merchant, a legal entity under public law or a special fund under public law, the place of jurisdiction for all disputes arising from this contract shall be our registered office.
        
                    § 14 Online Dispute Resolution
        
                    The European Commission provides the following platform for online dispute resolution (referred to as OS)
        
                    www.ec.europa.eu/consumers/odr
        
                    `
                )
            }




        </Container>
    )
}

export default TermsOfUse