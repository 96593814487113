import {
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useSepet } from "../../contexts/Sepet";
import { CardAppl } from "./Sepet.mui.style";

import { AiOutlineDelete } from "react-icons/ai";
import { useLanguage } from "../../contexts/Language";
import { useNavigate } from "react-router-dom";
import { useAddress } from "../../contexts/Address";

var formatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const Sepet = () => {
  const { sepet, deleteFromSepet, setProductDiscount } = useSepet();
  const { language, languageIndex } = useLanguage();
  const { setShippingDiscount } = useAddress();
  
  const navigate = useNavigate();
  const applyDiscount = () => {
    setProductDiscount(0)
    setShippingDiscount(0)
  }
  const handleNext = () => {
    if((sepet && sepet.length > 0)){
      navigate("/address");
      applyDiscount();
    }
  };
  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4, minHeight:"90vh" }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Stepper activeStep={0} alternativeLabel sx={{ pt: 3, pb: 5 }}>
            <Step>
              <StepLabel>
                {language == "tr" && "Sepet"}
                {language == "de" && "Warenkorb"}
                {language == "en" && "Shopping Cart"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel onClick={handleNext}>
                {language == "tr" && "Adres"}
                {language == "de" && "Adresse"}
                {language == "en" && "Address"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {language == "tr" && "Gözden geçir"}
                {language == "de" && "Überprüfung"}
                {language == "en" && "Review"}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {language == "tr" && "Ödeme"}
                {language == "de" && "Zahlung"}
                {language == "en" && "Payment"}
              </StepLabel>
            </Step>
          </Stepper>
          <Typography variant="h6" gutterBottom>
            {language == "tr" && "Sepeti onayla"}
            {language == "de" && "Warenkorb bestätigen"}
            {language == "en" && "Confirm shopping cart"}
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {language == "tr" && "Ürün"}
                    {language == "de" && "Produkt"}
                    {language == "en" && "Product"}
                  </TableCell>
                  <TableCell align="right">
                    {language == "tr" && "Adet"}
                    {language == "de" && "Menge"}
                    {language == "en" && "Quantity"}
                  </TableCell>
                  <TableCell align="right">
                    {language == "tr" && "Fiyat"}
                    {language == "de" && "Preis"}
                    {language == "en" && "Price"}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sepet.map((val) => {
                  return (
                    <TableRow key={Math.random()}>
                      <TableCell component="th" scope="row">
                        {val.title[languageIndex]}
                      </TableCell>
                      <TableCell align="right">{val.adet}</TableCell>
                      <TableCell align="right">
                        {formatter.format(val.price*val.adet)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteFromSepet(val.id)}
                        >
                          <AiOutlineDelete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
              disabled={!(sepet && sepet.length > 0)}
            >
              {language == "tr" && "İleri"}
              {language == "de" && "Nächste"}
              {language == "en" && "Next"}
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Sepet;
