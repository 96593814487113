export const toplamHesapla = (sepet, address) => {
  let toplam_kg = 0;
  let toplam_fiyat = 0;
  let shipping_price = 0;
  for (let i = 0; i < sepet.length; i++) {
    toplam_fiyat = toplam_fiyat + sepet[i]["price"] * sepet[i]["adet"];
  }
  let product_total = toplam_fiyat
  for (let i = 0; i < sepet.length; i++) {
    toplam_kg = toplam_kg + sepet[i]["weight"] * sepet[i]["adet"];
  }

  /**
   * https://www.myhermes.de/preise/paeckchen-paket/
   * https://www.dhl.de/en/privatkunden/pakete-versenden/deutschlandweit-versenden/preise-national.html
   * https://www.dhl.de/en/privatkunden/pakete-versenden/online-frankieren.html?type=ShipmentEditorProductSelection
   */
  if (address.shippingCompany == "hermes" && address.country == "Germany") {
    shipping_price = 5.95;
  } else if (address.shippingCompany == "hermes") {
    shipping_price = 13.9;
  } else if (address.shippingCompany == "dhl" && address.country == "Germany") {
    if (toplam_kg > 10) {
      shipping_price = 16.49;
    } else if (toplam_kg > 5) {
      shipping_price = 9.49;
    } else if (toplam_kg > 2) {
      shipping_price = 6.99;
    } else {
      shipping_price = 5.49;
    }
  }else if (address.shippingCompany == "dhl" ) {
    if (toplam_kg > 10) {
      shipping_price = 31.99;
    } else if (toplam_kg > 5) {
      shipping_price = 20.99;
    } else if (toplam_kg > 2) {
      shipping_price = 15.99;
    } else {
      shipping_price = 13.99;
    }
  } else if (address.shippingCompany == "express" && address.country == "Germany") {
    if (toplam_kg > 20) {
      shipping_price = 45;
    }else if (toplam_kg > 10) {
      shipping_price = 30;
    } else if (toplam_kg > 5) {
      shipping_price = 25;
    } else if (toplam_kg > 2) {
      shipping_price = 15;
    } else {
      shipping_price = 14;
    }
  } else if (address.shippingCompany == "express" ) {
    if (toplam_kg > 20) {
      shipping_price = 200;
    }else if (toplam_kg > 10) {
      shipping_price = 130;
    } else if (toplam_kg > 5) {
      shipping_price = 85;
    } else if (toplam_kg > 2) {
      shipping_price = 65;
    } else {
      shipping_price = 58;
    }
  }


  toplam_fiyat = toplam_fiyat + shipping_price;
  return [toplam_kg, toplam_fiyat, shipping_price, product_total];
};
