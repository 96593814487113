import { Typography, styled, Card, CardMedia } from "@mui/material";
export const Title = styled(Typography)`
  font-size: 120px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.002em;

  @media only screen and (max-width: 1068px) {
    font-size: 96px;
    line-height: 1.0416666667;
    font-weight: 600;
    letter-spacing: -0.015em;
  }
  @media only screen and (max-width: 734px) {
    font-size: 56px;
    line-height: 1.0714285714;
    font-weight: 600;
    letter-spacing: -0.005em;
  }
`;

export const SubTitle = styled(Typography)`
  border: 0;
  color: #1d1d1f;

  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0em;

  @media only screen and (max-width: 1068px) {
    font-size: 32px;
    line-height: 1.25;
    font-weight: 600;
    letter-spacing: 0.004em;
  }
  @media only screen and (max-width: 734px) {
    font-size: 24px;
    line-height: 1.1666666667;
    font-weight: 600;
    letter-spacing: 0.009em;
  }
`;

export const CusCard = styled(Card)`
  border: 0px;
  border-radius: 18px;
  box-shadow: none;
  position: relative;
  overflow: visible;
  margin-top: 28.12%;

  :hover {
    box-shadow: 0px 4px 33px 0px rgb(0 0 0 / 16%);
  }
`;

export const CardTitle = styled(Typography)`
  color: #1d1d1f;
  height: 70px;
  text-align: center;
`;
export const CardBody = styled(Typography)`
  color: #6e6e73;
  height: 75px;
  text-align: center;
`;

export const MyDialogTitle = styled(Typography)`
  border: 0;
  padding-left: 16px;
  padding-right: 16px;
  color: #1d1d1f;

  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0em;

  @media only screen and (max-width: 1068px) {
    font-size: 32px;
    line-height: 1.25;
    font-weight: 600;
    letter-spacing: 0.004em;
  }
  @media only screen and (max-width: 734px) {
    font-size: 24px;
    line-height: 1.1666666667;
    font-weight: 600;
    letter-spacing: 0.009em;
  }
`;

export const StyledCardMedia = styled(CardMedia)`
  height: 250px;
  @media only screen and (max-width: 1068px) {
    height: 200px;
  }
  @media only screen and (max-width: 734px) {
    height: 170px;
  }
`;
