import React, { useContext, createContext, useState, useEffect } from "react";

import firebase from "../utils/firebase";
import { useAuth } from "./Auth";
const OrdersContext = createContext();

const OrdersContextProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const { user } = useAuth();
  useEffect(() => {
    if (user && user.email == "cigkofteshopcom@gmail.com") {
      const uid = user.uid;
      const unsubscribe = firebase
        .firestore()
        .collection("orders")
        .orderBy("timestamp","desc")
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            const arr = [];
            snapshot.forEach((doc) => {
              arr.push({ ...doc.data() });
            });
            setOrders(arr);
          } else {
            setOrders([]);
          }
        });
      return unsubscribe;
    }
    return;
  }, [user]);
  useEffect(() => {
    setNewOrders(orders.filter((item) => item.status == "neworder"));
    setShippedOrders(orders.filter((item) => item.status == "shipped"));
  }, [orders]);

  return (
    <OrdersContext.Provider value={{ orders, newOrders, shippedOrders }}>
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => useContext(OrdersContext);

export default OrdersContextProvider;
